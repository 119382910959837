import { useEffect, useState } from 'react'
import types from 'prop-types'
import { Tabs } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

/**
 * @typedef {import('./TabsWithRoutingProps').TabsWithRoutingProps} TabsWithRoutingProps
 */

/**
 * @param {TabsWithRoutingProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const TabsWithRouting = ({
  children,
  isFitted,
  isLazy,
  onChange,
  queryString,
  tabs,
  variant,
  ...rest
}) => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const query = queryParams[queryString]
  const initialTab =
    query?.length > 0 ? tabs.findIndex(tab => tab.id === query) : 0
  const [tabIndex, setTabIndex] = useState(initialTab)

  useEffect(() => {
    if (!query) {
      const newQueryParams = {
        ...queryParams,
        [queryString]: tabs[0].id,
      }
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(newQueryParams),
      })
    }
  }, [history, location.pathname, query, queryParams, queryString, tabs])

  /**
   * @param {number} newTabIndex
   */
  const handleTabsChange = newTabIndex => {
    setTabIndex(newTabIndex)
    const currentTab = tabs[newTabIndex]
    const allQueryParams = {
      ...queryParams,
      [queryString]: currentTab.id,
    }

    history.replace({
      pathname: location.pathname,
      search: qs.stringify(allQueryParams),
    })

    if (onChange && typeof onChange === 'function') {
      onChange({ activeTabIndex: tabIndex })
    }
  }

  if (!children) {
    return null
  }

  return (
    <Tabs
      isFitted={isFitted}
      isLazy={isLazy}
      index={tabIndex}
      onChange={handleTabsChange}
      variant={variant}
      {...rest}>
      {children}
    </Tabs>
  )
}

TabsWithRouting.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]).isRequired,
  isFitted: types.bool,
  isLazy: types.bool,
  onChange: types.func,
  queryString: types.string.isRequired,
  tabs: types.arrayOf(
    types.shape({
      name: types.string,
      id: types.string,
    })
  ),
  variant: types.string,
}

TabsWithRouting.defaultProps = {
  isFitted: false,
  isLazy: false,
  queryString: 'activeTab',
  variant: 'solid',
}

TabsWithRouting.displayName = 'TabsWithRouting'
export default TabsWithRouting

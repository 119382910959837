import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

export const useWindowSize = () => {
  const [{ height, width }, setWindowHeight] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    const debouncedStoreResizedWindowHeight = debounce(handleWindowResize, 150)
    window.addEventListener('resize', debouncedStoreResizedWindowHeight)

    return () => {
      window.removeEventListener('resize', debouncedStoreResizedWindowHeight)
    }

    function handleWindowResize() {
      setWindowHeight({ height: window.innerHeight, width: window.innerWidth })
    }
  }, [])

  return {
    height,
    width,
  }
}

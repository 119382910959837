import { createContext } from 'react'
import types from 'prop-types'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import camelCase from 'lodash/camelCase'

import GetCurrentUser from 'shared/graphql/queries/GetCurrentUser.gql'

/**
 * @typedef {import('./ProviderProps').UserProviderProps} UserProviderProps
 * @typedef {import('./ProviderProps').Role} Role
 * @typedef {import('./ProviderProps').UserAttribute} UserAttribute
 * @typedef {import('./ProviderProps').UserPayload} UserPayload
 * @typedef {import('./ProviderProps').LogoutPayload} LogoutPayload
 * @typedef {import('./ProviderProps').User} User
 *
 */

/** @type {UserPayload} */
const initialData = {
  isAuthenticated: false,
  isLoading: false,
  isUpdating: false,
  loginWithRedirect: () => null,
  logout: () => {},
  user: null,
}

export const UserContext = createContext(initialData)

/**
 * @param {UserProviderProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const UserProvider = ({ children }) => {
  const auth0Payload = useAuth0()
  const { data, loading } = useQuery(GetCurrentUser, {
    nextFetchPolicy: 'cache-and-network',
    skip: auth0Payload.isLoading || !auth0Payload.isAuthenticated,
  })

  /**
   * @param {string} role
   */
  function hasRole(role) {
    if (!data) {
      console.error(
        `* [hasRole] Error caught. Received unexpected ${role} role param`
      )
      return null
    }
    return data.myRoles.nodes.some(
      /** @param {Role} node */ node => node.roleName === role
    )
  }
  /**
   * @param {string[]} roles
   */
  function hasRoles(roles) {
    if (!data) {
      console.error(
        `* [hasRoles] Error caught. Received unexpected ${roles} roles param`
      )
      return null
    }

    return roles.every(role =>
      data.myRoles.nodes.some(
        /** @param {Role} node */ node => node.roleName === role
      )
    )
  }

  /** @type {User} */
  const user = Object.assign({}, data?.currentUser, {
    roles:
      data?.myRoles.nodes.map(
        /** @param {Role} role */ role => role.roleName
      ) ?? [],
    hasRole,
    hasRoles,
    userAttributes: data?.currentUser.userAttributes.nodes.reduce(
      (
        /** @type {Object.<string, string>} */ acc,
        /** @type {UserAttribute} */ curr
      ) => ({
        ...acc,
        [camelCase(curr.attributeSlug)]: curr.value,
      }),
      {}
    ),
  })

  const userData = Object.assign({}, auth0Payload, {
    isLoading: data == null && (auth0Payload.isLoading || loading),
    isUpdating: data != null && (auth0Payload.isLoading || loading),
    user: data == null ? null : user,
  })

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]).isRequired,
}

UserProvider.displayName = 'ApolloProvider'
export default UserProvider

import { lazy } from 'react'
import { withSuspense } from 'shared/decorators'
import { ProfileIcon, NotificationsIcon } from 'assets/icons'

const LazyProfileDetails = lazy(() => import('./details/Details'))
const LazyNotifications = lazy(() => import('./notifications/Notifications'))

export const routes = [
  {
    path: 'details',
    name: 'My info',
    Component: withSuspense(LazyProfileDetails),
    Icon: ProfileIcon,
    id: 'profile-detials',
    requiredRoles: [],
    childRoutes: [],
  },
  {
    path: 'notifications',
    name: 'Notifications',
    Component: withSuspense(LazyNotifications),
    Icon: NotificationsIcon,
    id: 'notifications',
    requiredRoles: [],
    childRoutes: [],
  },
]

export default routes

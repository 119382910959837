import { useEffect, useRef } from 'react'

/**
 * @type {ScrollIntoViewOptions}
 */
const scrollingOptions = {
  behavior: 'smooth',
  block: 'end',
  inline: 'nearest',
}

/**
 * @param {Object} config - hook configuration
 * @param {'horizontal'|'vertical'} config.direction - the direction of navigation
 * @returns {React.MutableRefObject<HTMLDivElement>} elementRef - reference to the element. Access it through `elementRef.current`
 */
export const useStackNavigation = ({ direction = 'vertical' }) => {
  const elRef = useRef(/** @type {HTMLDivElement|null} */ (null))

  useEffect(() => {
    const targetElement = elRef.current

    if (targetElement) {
      targetElement.addEventListener('keyup', handleKeyPress)
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('keyup', handleKeyPress)
      }
    }

    /**
     * @param {KeyboardEvent} event
     */
    function handleKeyPress(event) {
      const { key, target } = event
      if (key === 'Enter') {
        const event = document.createEvent('Event')
        event.initEvent('click', true, true)

        target.dispatchEvent(event)
        return null
      }

      const allowedNavigationDirection = ['vertical', 'horizontal']

      if (!allowedNavigationDirection.includes(direction)) {
        return console.error(
          `* Invalid direction "${direction}" provided to useStackNavigation. Expected one of ${allowedNavigationDirection}`
        )
      }

      if (direction === 'horizontal') {
        handleHorizontalScroll({ key, nodeElement: target })
      } else {
        handleVerticalScroll({ key, nodeElement: target })
      }
    }
  }, [direction])

  /**
   *
   * @param {Object} actionAttributes - key pressed and target node
   * @param {string} actionAttributes.key - key pressed
   * @param {EventTarget} actionAttributes.nodeElement
   * @returns
   */
  function handleVerticalScroll(actionAttributes) {
    const { key, nodeElement } = actionAttributes
    // @ts-ignore
    const { nextElementSibling, previousElementSibling } = nodeElement

    if (key === 'ArrowDown' && nextElementSibling) {
      nextElementSibling.scrollIntoView(scrollingOptions)
      return nextElementSibling.focus()
    } else if (key === 'ArrowUp' && previousElementSibling) {
      previousElementSibling.scrollIntoView(scrollingOptions)
      return previousElementSibling.focus()
    }
    return null
  }

  /**
   *
   * @param {Object} actionAttributes - key pressed and target node
   * @param {string} actionAttributes.key - key pressed
   * @param {EventTarget} actionAttributes.nodeElement
   * @returns
   */
  function handleHorizontalScroll(actionAttributes) {
    const { key, nodeElement } = actionAttributes
    // @ts-ignore
    const { nextElementSibling, previousElementSibling } = nodeElement

    if (key === 'ArrowRight' && nextElementSibling) {
      nextElementSibling.scrollIntoView(scrollingOptions)
      return nextElementSibling.focus()
    } else if (key === 'ArrowLeft' && previousElementSibling) {
      previousElementSibling.scrollIntoView(scrollingOptions)
      return previousElementSibling.focus()
    }
    return null
  }

  return elRef
}

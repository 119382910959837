import types from 'prop-types'
import { HStack, Icon, IconButton, Text } from '@chakra-ui/react'
import { keyframes } from '@chakra-ui/system'
import { Loader, RefreshCcw } from 'react-feather'

/**
 *
 * @param {Object} props
 * @param {string} [props.alignSelf]
 * @param {string} props.label
 * @param {boolean} props.isLoading
 * @param {() => void} props.onRefresh
 * @param {number | string} props.value
 * @param {number | string} [props.mt]
 * @returns {JSX.Element}
 */
const LastUpdated = ({ alignSelf, isLoading, label, onRefresh, value }) => {
  return (
    <HStack alignSelf={alignSelf} spacing={4}>
      <Text color='gray.700' fontSize='sm' fontWeight='medium'>
        {label} {value}{' '}
      </Text>
      {isLoading ? (
        <Icon
          animation={`${spin} 750ms linear infinite`}
          as={Loader}
          color='gray.700'
          height={4}
          width={4}
          transition='transform 150ms ease-in-out'
        />
      ) : (
        <IconButton
          aria-label='Refresh list of cycles'
          border='none'
          icon={
            <Icon
              as={RefreshCcw}
              color='gray.600'
              _hover={{
                color: 'gray.800',
              }}
              height={4}
              width={4}
            />
          }
          isRound
          onClick={() => onRefresh()}
          size='2xs'
          title='Refresh list of cycles'
          variant='outline'
        />
      )}
    </HStack>
  )
}

const spin = keyframes`
  100% { 
    transform:rotate(360deg); 
  } 
`
LastUpdated.propTypes = {
  alignSelf: types.string,
  isLoading: types.bool,
  label: types.string,
  onRefresh: types.func,
  value: types.oneOfType([types.number, types.string]),
}
LastUpdated.defaultProps = {
  label: 'Updated at:',
  value: '-',
}

LastUpdated.displayName = 'LastUpdated'
export default LastUpdated

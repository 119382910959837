import types from 'prop-types'
import { Prompt } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useAuth } from 'shared/hooks'

import Splash from 'components/Splash'
import { useNetworkStatus } from 'shared/hooks/useNetworkStatus'
import GuardedRoute from './GuardedRoute'

/**
 * @typedef {import('./RouteProps').AuthedRouteProps} AuthedRouteProps
 */

/**
 * @param {AuthedRouteProps} props
 * @returns {JSX.Element}
 */
const PrivateRoute = ({ element: Element, ...props }) => {
  const { isOnline } = useNetworkStatus()
  const { isAuthenticated, logout, user } = useAuth()

  if (!Element) {
    console.error('* Please provide an "element" prop to AuthedRoute')
    return null
  }

  return (
    <GuardedRoute
      exact={props.exact}
      path={props.path}
      guards={[isAuthenticated]}>
      <Element logout={logout} user={user} {...props} />
      <Prompt
        message='Your internet connection is down. Navigating offline could crash the application. Are you sure you wish to continue?'
        when={!isOnline}
      />
    </GuardedRoute>
  )
}

const AuthedRoute = withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <Splash message='Loading...' />,
})

PrivateRoute.propTypes = {
  exact: types.bool,
  element: types.func,
  path: types.string.isRequired,
  render: types.func,
}

PrivateRoute.displayName = 'PrivateRoute'
AuthedRoute.displayName = 'AuthedRoute'
export default AuthedRoute

import types from 'prop-types'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
/**
 * @typedef {Object} Crumb
 * @property {boolean} [isCurrentPage]
 * @property {string} name
 * @property {string} [to]
 *
 * @param {Object} props
 * @param {Crumb[]} props.links
 * @param {string | JSX.Element} [props.separator]
 * @returns
 */
const Breadcrumbs = ({ links, separator, ...props }) => {
  return (
    <Breadcrumb {...props}>
      {links.map((crumb, ix) => (
        <BreadcrumbItem
          key={crumb.name ?? String(ix)}
          isCurrentPage={crumb.isCurrentPage}>
          <BreadcrumbLink
            as={crumb.to ? Link : null}
            to={crumb.to ?? null}
            color={crumb.isCurrentPage ? 'gray.600' : 'info.500'}
            fontSize={{ base: 'xs', md: 'sm' }}
            letterSpacing='wide'
            fontWeight='medium'
            textDecoration={crumb.isCurrentPage ? 'underline' : null}
            textUnderlineOffset='4px'
            textTransform='uppercase'>
            {crumb.name ?? '--'}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  )
}

Breadcrumbs.propTypes = {
  links: types.arrayOf(
    types.shape({
      isCurrentPage: types.bool,
      name: types.string,
      to: types.string,
    })
  ),
  separator: types.oneOfType([types.string, types.node]),
}
Breadcrumbs.defaultProps = {
  separator: '/',
}

Breadcrumbs.displayName = 'Breadcrumbs'
export default Breadcrumbs

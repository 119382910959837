import types from 'prop-types'
import { Badge as ChakraBadge, Skeleton } from '@chakra-ui/react'

import { BadgeVariants, ColorVariants, FontSizeTypes } from 'shared/nio-types'

/**
 * @typedef {Object} BadgeProps
 * @property {string} colorScheme
 * @property {number} [flex]
 * @property {string | Object.<string,string>} [fontSize]
 * @property {string} label
 * @property {boolean} [isLoading]
 * @property {number} [ml]
 * @property {number} [px]
 * @property {number} [py]
 * @property {string} variant
 *
 * @param {BadgeProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const Badge = ({
  colorScheme,
  fontSize,
  label,
  isLoading,
  variant,
  ...props
}) => (
  <Skeleton borderRadius='full' isLoaded={!isLoading}>
    <ChakraBadge
      borderRadius='full'
      fontSize={fontSize}
      fontWeight='semibold'
      colorScheme={colorScheme}
      textTransform='capitalize'
      variant={variant}
      {...props}>
      {label.toLowerCase()}
    </ChakraBadge>
  </Skeleton>
)

Badge.propTypes = {
  colorScheme: types.oneOf(ColorVariants),
  fontSize: types.oneOfType([
    types.oneOf(FontSizeTypes),
    types.objectOf(types.oneOf(FontSizeTypes)),
  ]),
  label: types.string,
  isLoading: types.bool,
  variant: types.oneOf(BadgeVariants),
}

Badge.defaultProps = {
  colorScheme: 'info',
  fontSize: 'sm',
  isLoading: false,
  label: 'Unknown',
  variant: 'solid',
}

Badge.displayName = 'Badge'
export default Badge

import types from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'

import Select from 'shared/components/select'
import { ColorVariants } from 'shared/nio-types'

/**
 * @typedef {import('./FormProps').FormSelectProps} FormSelectProps
 * @typedef {import('shared/nio-types/types').ListItem} ListItem
 */

/**
 * @param {FormSelectProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const FormSelect = ({
  className,
  closeMenuOnSelect,
  colorScheme,
  dataTestId,
  id,
  isClearable,
  isDisabled,
  isLoading,
  isMulti,
  isReadOnly,
  isSearchable,
  inputValue,
  helperText,
  label,
  name,
  onInputChange,
  options,
  placeholder,
  size,
  variant,
  ...rest
}) => {
  const {
    control,
    formState,
    // @ts-ignore
    initialValues,
    // @ts-ignore
    isInitializing,
    // @ts-ignore
    validationSchema,
  } = useFormContext()

  const defaultValue = initialValues[name] ?? null
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ name, control, defaultValue })
  /**
   * @param {number | string | ListItem} value
   * @returns {ListItem}
   */
  const formatValue = value => {
    if (typeof value === 'object') {
      return value
    }

    return options.find(option => option.value === value)
  }
  const error = formState.errors[name]
  const isRequired = validationSchema?.fields[name]?.exclusiveTests?.required

  return (
    <FormControl
      className={className}
      defaultValue={defaultValue}
      isDisabled={isDisabled || isInitializing}
      isRequired={isRequired}
      isInvalid={error != null}
      isReadOnly={isReadOnly}
      label={label}
      size={size}
      {...rest}>
      {label ? (
        <FormLabel
          color='gray.500'
          fontSize='xs'
          fontWeight='normal'
          htmlFor={name}>
          {label}
        </FormLabel>
      ) : null}
      <Select
        aria-invalid={error?.message ? 'true' : undefined}
        aria-required={isRequired}
        closeMenuOnSelect={closeMenuOnSelect}
        colorScheme={colorScheme}
        dataTestId={dataTestId}
        id={id}
        inputValue={inputValue}
        isClearable={isClearable}
        isLoading={isInitializing || isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        name={name}
        onBlur={onBlur}
        onChange={option => onChange(option?.value ?? null)}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        ref={ref}
        value={formatValue(value)}
        variant={variant}
      />
      <FormErrorMessage fontSize='xs' fontWeight='normal' role='alert'>
        {error?.message}
      </FormErrorMessage>
      {helperText ? (
        <FormHelperText fontSize='xs' fontWeight='normal'>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

FormSelect.propTypes = {
  className: types.string,
  closeMenuOnSelect: types.bool,
  colorScheme: types.oneOf(ColorVariants),
  control: types.object,
  dataTestId: types.string,
  helperText: types.string,
  id: types.string,
  inputValue: types.string,
  isClearable: types.bool,
  isDisabled: types.bool,
  isLoading: types.bool,
  isMulti: types.bool,
  isReadOnly: types.bool,
  isSearchable: types.bool,
  label: types.string,
  name: types.string.isRequired,
  onInputChange: types.func,
  options: types.arrayOf(
    types.shape({
      label: types.string,
      options: types.shape({
        label: types.string,
        value: types.oneOfType([types.string, types.number]),
      }),
      value: types.oneOfType([types.string, types.number]),
    })
  ),
  placeholder: types.string,
  size: types.string,
  variant: types.string,
}

FormSelect.defaultProps = {
  className: '',
  closeMenuOnSelect: true,
  defaultValue: null,
  isDisabled: false,
  isMulti: false,
  isReadOnly: false,
  isRequired: false,
  isSearchable: false,
  label: null,
  options: [],
  placeholder: 'Select option',
}

FormSelect.displayName = 'FormSelect'
export default FormSelect

import { cloneElement } from 'react'
import types from 'prop-types'
import { useTheme, useColorModeValue, useFormControl } from '@chakra-ui/react'

import {
  Control,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  IndicatorSeparator,
  ClearIndicator,
  DropdownIndicator,
  MenuPortal,
  Menu,
  MenuList,
  GroupHeading,
  Option,
} from './ChakraComponents'
import { chakraStyles } from './chakraStyles'

/**
 * @typedef {import('./SelectProps').SelectProps} SelectProps
 */

/** @readonly */
const selectVariants = Object.freeze(['subtle', 'solid', 'outline'])

/**
 * @param {SelectProps} props
 * @returns {JSX.Element}
 */
const ChakraReactSelect = ({
  children,
  closeMenuOnSelect,
  style,
  components,
  theme,
  size,
  colorScheme,
  hasStickyGroupHeaders,
  id,
  inputId,
  isDisabled,
  isInvalid,
  isMulti,
  isRequired,
  variant,
  ...props
}) => {
  const chakraTheme = useTheme()

  // Combine the props passed into the component with the props
  // that can be set on a surrounding form control to get
  // the values of isDisabled and isInvalid
  const inputProps = useFormControl({ isDisabled, isInvalid, isRequired })

  // The chakra theme styles for TagCloseButton when focused
  const closeButtonFocus =
    chakraTheme.components.Tag.baseStyle.closeButton._focus

  const multiValueRemoveFocusStyle = {
    bg: closeButtonFocus.bg,
    boxShadow: chakraTheme.shadows[closeButtonFocus.boxShadow],
  }

  // The chakra UI global placeholder color
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts#L13
  const placeholderColor = useColorModeValue(
    chakraTheme.colors.gray[400],
    chakraTheme.colors.whiteAlpha[400]
  )

  // Ensure that the size used is one of the options, either `sm`, `md`, or `lg`
  let realSize = size
  const sizeOptions = ['sm', 'md', 'lg']
  if (!sizeOptions.includes(size)) {
    realSize = 'md'
  }

  // Ensure that the tag variant used is one of the options, either `subtle`,
  // `solid`, or `outline` (or )
  let realTagVariant = variant
  if (realTagVariant?.length > 0) {
    if (!selectVariants.includes(realTagVariant)) {
      realTagVariant = 'outline'
    }
  }

  let derivedCloseMenuOnSelect = closeMenuOnSelect
  if (derivedCloseMenuOnSelect === undefined) {
    if (isMulti) {
      derivedCloseMenuOnSelect = false
    } else {
      derivedCloseMenuOnSelect = true
    }
  }

  const Select = cloneElement(children, {
    components: {
      Control,
      MultiValueContainer,
      MultiValueLabel,
      MultiValueRemove,
      IndicatorSeparator,
      ClearIndicator,
      DropdownIndicator,
      MenuPortal,
      Menu,
      MenuList,
      GroupHeading,
      Option,
      ...components,
    },
    styles: {
      ...chakraStyles,
      ...style,
    },
    theme: baseTheme => {
      let propTheme = {}
      if (typeof theme === 'function') {
        propTheme = theme(baseTheme)
      }

      return {
        ...baseTheme,
        ...propTheme,
        colors: {
          ...baseTheme.colors,
          neutral40: placeholderColor, // noOptionsMessage color
          neutral50: placeholderColor, // placeholder text color
          ...propTheme.colors,
        },
        spacing: {
          ...baseTheme.spacing,
          ...propTheme.spacing,
        },
      }
    },
    colorScheme,
    closeMenuOnSelect: derivedCloseMenuOnSelect,
    size: realSize,
    tagVariant: realTagVariant,
    multiValueRemoveFocusStyle,
    // isDisabled and isInvalid can be set on the component
    // or on a surrounding form control
    isDisabled: inputProps.disabled,
    isInvalid: !!inputProps['aria-invalid'],
    id: id ?? inputProps.id,
    inputId: inputId ?? inputProps.id,
    isMulti,
    hasStickyGroupHeaders,
    ...props,
    // aria-invalid can be passed to react-select, so we allow that to
    // override the `isInvalid` prop
    'aria-invalid':
      props['aria-invalid'] ?? inputProps['aria-invalid'] ? true : undefined,
  })

  return Select
}

ChakraReactSelect.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  closeMenuOnSelect: types.bool,
  colorScheme: types.string,
  components: types.object,
  id: types.string,
  inputId: types.string,
  isDisabled: types.bool,
  isInvalid: types.bool,
  isMulti: types.bool,
  isRequired: types.bool,
  size: types.string,
  style: types.object,
  theme: types.func,
  variant: types.oneOf(selectVariants),
}
ChakraReactSelect.defaultProps = {
  isMulti: false,
  style: {},
  components: {},
  theme: () => ({}),
  size: 'md',
}

ChakraReactSelect.displayName = 'ReactSelect'
export default ChakraReactSelect

/** @readonly */
export const cycleStatuses = Object.freeze({
  COMPLETE: 'COMPLETE',
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  READY: 'READY',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  UNLOADING: 'UNLOADING',
  IDLE_PRE_BLAST: 'IDLE PRE-BLAST',
  IDLE_POST_BLAST: 'IDLE POST-BLAST',
})

/** @readonly */
export const loadStatuses = Object.freeze({
  ARRIVED: 'ARRIVED',
  FINISHED: 'FINISHED',
  INCOMING: 'INCOMING',
  UNLOADING: 'UNLOADING',
})

/** @readonly */
export const proposalStatuses = Object.freeze({
  APPROVED: 'APPROVED',
  CANCEL_REQUESTED: 'CANCEL_REQUESTED',
  CANCELLED: 'CANCELLED',
  COMPLETE: 'COMPLETE',
  EXCEPTION: 'EXCEPTION',
  IN_PROGRESS: 'IN PROGRESS',
  IN_REVIEW: 'IN REVIEW',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REVIEW_REJECTED: 'REVIEW_REJECTED',
  SCHEDULED: 'SCHEDULED',
  UNACKNOWLEDGED: 'UNACKNOWLEDGED',
  IDLE_PRE_BLAST: 'IDLE PRE-BLAST',
  IDLE_POST_BLAST: 'IDLE POST-BLAST',
})

/** @readonly */
export const userRoles = Object.freeze({
  BASE_USER: 'base_user',
  BLAST_USER: 'blast_user',
  CONTXT_CONTROL_ADMIN: 'contxt_control_admin',
  CONTXT_CONTROL_USER: 'contxt_control_user',
  WMS_USER: 'wms_user',
})

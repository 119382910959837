import types from 'prop-types'
import { useToken } from '@chakra-ui/react'
/**
 * @typedef {import('./IconProps').IconProps} IconProps
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */
const NotificationsIcon = ({ isActive, ...props }) => {
  const [activeIconColor, inactiveBaseIconColor, inactiveIconColor, white] =
    useToken('colors', ['primary.base', 'gray.700', 'gray.400', 'white'])

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      {...props}>
      <path
        clipRule='evenodd'
        d='M 11.99 23.449 C 18.33 23.449 23.469 18.319 23.469 11.993 C 23.469 5.665 18.33 0.536 11.99 0.536 C 5.649 0.536 0.51 5.665 0.51 11.993 C 0.51 18.319 5.649 23.449 11.99 23.449 Z'
        fill={isActive ? activeIconColor : 'none'}
        stroke={isActive ? white : inactiveIconColor}
      />
      <path
        d='M 16.712 9.337 C 16.712 5.934 12.769 3.808 9.616 5.511 C 8.153 6.3 7.25 7.758 7.25 9.337 C 7.25 14.494 4.885 15.968 4.885 15.968 L 19.077 15.968 C 19.077 15.968 16.712 14.494 16.712 9.337'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M 13.48 18.131 C 12.808 19.275 11.136 19.275 10.464 18.131'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

NotificationsIcon.propTypes = {
  isActive: types.bool,
}
NotificationsIcon.defaultProps = {
  isActive: false,
}

NotificationsIcon.displayName = 'NotificationsIcon'
export default NotificationsIcon

import types from 'prop-types'
import { useToken } from '@chakra-ui/react'
/**
 * @typedef {import('./IconProps').IconProps} IconProps
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */
const ControlAndSuggestionsIcon = ({ isActive, ...props }) => {
  const [activeIconColor, inactiveBaseIconColor, inactiveIconColor, white] =
    useToken('colors', ['primary.base', 'gray.700', 'gray.400', 'white'])

  return (
    <svg
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        clipRule='evenodd'
        d='M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z'
        fill={isActive ? activeIconColor : 'none'}
        stroke={isActive ? white : inactiveIconColor}
      />
      <path
        clipRule='evenodd'
        d='M9.667 12.334a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM15 17a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM20.334 9a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m14.673 15.273 2.654-4.553M9 13.833l2 1.833'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

ControlAndSuggestionsIcon.propTypes = {
  isActive: types.bool,
}
ControlAndSuggestionsIcon.defaultProps = {
  isActive: false,
}

ControlAndSuggestionsIcon.displayName = 'ControlAndSuggestionsIcon'
export default ControlAndSuggestionsIcon

import types from 'prop-types'
import { useToken } from '@chakra-ui/react'
/**
 * @typedef {import('./IconProps').IconProps} IconProps
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */
const TodayIcon = ({ isActive, ...props }) => {
  const [activeIconColor, inactiveBaseIconColor, inactiveIconColor, white] =
    useToken('colors', ['primary.base', 'gray.700', 'gray.400', 'white'])

  return (
    <svg
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        clipRule='evenodd'
        d='M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z'
        fill={isActive ? activeIconColor : 'none'}
        stroke={isActive ? white : inactiveIconColor}
      />
      <g
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'>
        <path
          clipRule='evenodd'
          d='m7 11 6-4.667L19 11v7.334c0 .736-.597 1.333-1.333 1.333H8.333A1.333 1.333 0 0 1 7 18.333V11Z'
        />
        <path d='M11 19.667V13h4v6.667' />
      </g>
    </svg>
  )
}

TodayIcon.propTypes = {
  isActive: types.bool,
}
TodayIcon.defaultProps = {
  isActive: false,
}

TodayIcon.displayName = 'TodayIcon'
export default TodayIcon

import { theme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const commonStyle = {
  borderRadius: 'full',
  fontSize: 'sm',
  fontWeight: 'medium',
  textTransform: 'uppercase',
}

export const Button = {
  variants: {
    solid: props => {
      const { colorScheme: c } = props

      return {
        ...theme.components.Button.variants.solid(props),
        ...commonStyle,
        bg: mode(`${c}.base`, `${c}.700`)(props),
        color: 'white',
      }
    },
    outline: props => {
      const { colorScheme: c } = props

      return {
        ...theme.components.Button.variants.outline(props),
        ...commonStyle,
        border: '1px',
        borderColor: mode(`${c}.base`, `${c}.base`)(props),
        color: mode(`${c}.600`, `${c}.600`)(props),
      }
    },
    ghost: props => {
      const { colorScheme: c } = props

      return {
        ...theme.components.Button.variants.ghost(props),
        ...commonStyle,
        borderRadius: 'base',
        color: mode(`${c}.base`, `${c}.base`)(props),
      }
    },
  },
}

/** @readonly */
export const ButtonVariants = ['solid', 'outline', 'ghost', 'link', 'unstyled']

import { useParams } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import GetFacilities from 'shared/graphql/queries/GetFacilities.gql'

/**
 * @typedef {import('shared/components/selectFacility/SelectFacilityProps').Facility} Facility
 */

export const useCurrentFacility = () => {
  // @ts-ignore
  const { facilityId } = useParams()
  const client = useApolloClient()

  const data = client.readQuery({
    query: GetFacilities,
  })

  if (!facilityId) {
    console.error(
      `* Unexpected error caught in useCurrentFacility. Expected facilityId, but got ${facilityId}`
    )
    return
  }

  if (!data) {
    console.error(
      `* Unexpected error caught in useCurrentFacility. Expected list of facilities, but got ${data}`
    )
  }
  return data.facilities.nodes.find(
    /** @param {Facility} facility */ facility =>
      facility.id === Number(facilityId)
  )
}

import types from 'prop-types'
import { Flex, Text } from '@chakra-ui/react'

import TimelineItem from './TimelineItem'
import TimelineSkeleton from './TimelineSkeleton'

/**
 * @typedef {import('./TimelineTabProps').TimelineTabProps} TimelineTabProps
 */

/**
 * @param {TimelineTabProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const Timeline = ({ data, fallback, isLoading }) => {
  if (isLoading) {
    return <TimelineSkeleton />
  }

  return (
    <Flex
      as='figure'
      flexDirection='column'
      height='100%'
      overflowY='auto'
      width='100%'>
      {data?.length > 0
        ? data.map(entry => (
            <TimelineItem
              key={(Math.random() + 1).toString(36).substring(10)}
              data={entry}
              isLoading={isLoading}
            />
          ))
        : fallback}
    </Flex>
  )
}

Timeline.propTypes = {
  data: types.arrayOf(
    types.shape({
      description: types.string,
      eventTime: types.string.isRequired,
      eventType: types.string.isRequired,
    })
  ),
  fallback: types.node,
  isLoading: types.bool,
}
Timeline.defaultProps = {
  fallback: <Text>No timeline is currently available.</Text>,
}

Timeline.displayName = 'Timeline'
export default Timeline

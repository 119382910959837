import { Box, Flex, Image } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router-dom'

import { useAuth } from 'shared/hooks'
import { Header, SelectFacility } from 'shared/components'
import appLogo from 'assets/images/app-logo.svg'
import pendingAction from 'assets/images/pending-action.svg'

/**
 * @returns {JSX.Element}
 */
const Landing = () => {
  const { user } = useAuth()

  if (user.defaultFacility?.id) {
    return <Redirect to={`/facilities/${user.defaultFacility?.id}`} />
  }

  return (
    <Box height='100%' width='100%'>
      <Helmet>
        <title>Warehousing - Landing</title>
        <meta name='description' content='Ndustrial.io warehousing' />
      </Helmet>
      <Header
        dataTestId='landing-header'
        renderLeft={() => (
          <Image
            alt='Ndustrial Logo'
            src={appLogo}
            height='100%'
            width='160px'
          />
        )}
      />

      <Flex
        as='figure'
        alignItems='center'
        flexDirection='column'
        height='100%'
        width='100%'>
        <Image
          alt='landing crane'
          src={pendingAction}
          height='100%'
          maxHeight={{ base: '240x', lg: '400px', '2xl': '440px' }}
          minWidth='400px'
          mt={6}
          objectFit='contain'
          width='100%'
        />
        <Box mt={3} minWidth='240px' maxWidth='340px' width='100%'>
          <SelectFacility dataTestId='landing-select-facility' />
        </Box>
      </Flex>
    </Box>
  )
}

Landing.propTypes = {}

Landing.displayName = 'FacilitiesLanding'
export default Landing

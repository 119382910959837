import types from 'prop-types'
import { Container, useTheme } from '@chakra-ui/react'

/**
 * @typedef {import('./SidePanelProps').SidePanelProps} SidePanelProps
 */

/**
 * @param {SidePanelProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const SidePanel = ({
  bg,
  borderLeft,
  borderLeftColor,
  children,
  isVisible,
  top,
  overflowY,
}) => {
  const theme = useTheme()

  return (
    <Container
      as='aside'
      bg={bg}
      borderLeft={borderLeft}
      borderLeftColor={borderLeftColor}
      height='100%'
      overflowY={overflowY}
      position='fixed'
      right={isVisible ? 0 : `-${theme.sidePanel.width}`}
      top={top < 0 ? theme.header.height : top}
      transition='right 330ms ease-in-out'
      width={theme.sidePanel.width}>
      {children}
    </Container>
  )
}

SidePanel.propTypes = {
  bg: types.string,
  borderLeft: types.string,
  borderLeftColor: types.string,
  children: types.oneOfType([types.arrayOf(types.node), types.node]).isRequired,
  isVisible: types.bool.isRequired,
  overflowY: types.string,
  top: types.number,
}

SidePanel.defaultProps = {
  bg: 'white',
  borderLeft: 'solid 1px',
  borderLeftColor: 'transparent',
  isVisible: false,
  overflowY: 'auto',
  top: -1,
}

SidePanel.displayName = 'SidePanel'
export default SidePanel

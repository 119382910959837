import { createContext, useEffect, useState } from 'react'
import types from 'prop-types'

export const NetworkStatusContext = createContext({
  isOnline: navigator.onLine,
})

/**
 * @typedef {import('./ProviderProps').NetworkStatusProviderProps} NetworkStatusProviderProps
 */

/**
 * @param {NetworkStatusProviderProps} props
 * @returns
 */
export const NetworkStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    window.addEventListener('online', handleNetworkStatusChange)
    window.addEventListener('offline', handleNetworkStatusChange)

    return () => {
      window.removeEventListener('online', handleNetworkStatusChange)
      window.removeEventListener('offline', handleNetworkStatusChange)
    }

    /**
     * @param {Event} event
     */
    function handleNetworkStatusChange(event) {
      switch (event.type) {
        case 'online':
          setIsOnline(true)
          break
        case 'offline':
          setIsOnline(false)
          break
        default:
          console.error(
            `* Invalid event type ${event.type} caught in NetworkStatusProvider.`
          )
          break
      }
    }
  }, [])

  return (
    <NetworkStatusContext.Provider value={{ isOnline }}>
      {children}
    </NetworkStatusContext.Provider>
  )
}

NetworkStatusProvider.propTypes = {
  children: types.node.isRequired,
}

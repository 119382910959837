export const Table = {
  variants: {
    nio: {
      table: {
        border: '1px',
        borderColor: 'gray.300',
      },
      th: {
        bg: 'background.tableHeader',
        borderBottomWidth: '1px',
        borderBottomColor: 'gray.300',
        textTransform: 'capitalize',
      },
      td: {
        bg: 'white',
        borderBottom: '1px',
        borderBottomColor: 'gray.300',
        paddingY: 3,
      },
    },
  },
}

/** @readonly */
export const TableVariants = ['nio', 'simple', 'unstyled', 'stripped']

import { useCallback } from 'react'
import { useToast as useChakraToast } from '@chakra-ui/react'

const defaultConfig = {
  isClosable: true,
  duration: 4000,
  position: 'bottom',
  variant: 'solid',
}

/**
 * @typedef {Object} ToastProperties
 * @property {string} [description]
 * @property {number} [duration]
 * @property {string} [id]
 * @property {boolean} [isClosable]
 * @property {() => {}} [onCloseComplete]
 * @property {'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right'} [position]
 * @property {'info' | 'warning' | 'success' | 'error'} status
 * @property {string} title
 */

/**
 * @typedef {Object} Toast
 * @property {(properties: ToastProperties) => void} show
 * @property {(id: string) => void} close
 * @property {(options?: Object) => void} closeAll
 * @property {(id: string) => boolean} isActive
 * @returns {Toast} toast
 */
export const useToast = () => {
  const toast = useChakraToast()

  const createToast = useCallback(
    /** @param {ToastProperties} properties */ properties => {
      const config = Object.assign({}, defaultConfig, properties)
      const result = toast(config)
      return result
    },
    [toast]
  )

  return Object.assign(toast, {
    show: createToast,
  })
}

import types from 'prop-types'
import { Box, chakra, Flex, Text } from '@chakra-ui/react'
import { keyframes } from '@chakra-ui/system'

import { SizeTypes } from 'shared/nio-types'
import { shouldForwardProp } from 'shared/utils'

/**
 * @typedef {import('@emotion/react').SerializedStyles} SerializedStyles
 * @typedef {import('./SplashProps').SplashProps} SplashProps
 */

/**
 * @param {SplashProps} props
 * @returns {JSX.Element}
 */
const Splash = ({ containerStyle, labelStyle, message }) => {
  return (
    <Flex
      alignItems='center'
      flexDirection='column'
      height='100%'
      justifyContent='center'
      position='fixed'
      margin='auto'
      width='100%'
      {...containerStyle}>
      <Ripple className='spinner'>
        <div className='double-bounce1'></div>
        <div className='double-bounce2'></div>
      </Ripple>
      {message ? (
        <Text
          color='neutral.700'
          fontSize='sm'
          ml='1ch'
          mt={2}
          textAlign='center'
          textTransform='uppercase'
          {...labelStyle}>
          {message}
        </Text>
      ) : null}
    </Flex>
  )
}

const bounce = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`

/**
 * @param {Object} props
 * @param {string} props.size
 * @returns {SerializedStyles}
 */

const Ripple = chakra(Box, {
  baseStyle: {
    position: 'relative',
    height: '44px',
    width: '44px',

    '.double-bounce1, .double-bounce2': {
      animation: `${bounce} 2000ms infinite ease-in-out`,
      bg: 'neutral.400',
      borderRadius: 'round',
      height: '100%',
      left: 0,
      opacity: 0.6,
      position: 'absolute',
      top: 0,
      width: '100%',
    },

    '.double-bounce2': {
      animationDelay: '-1000ms',
    },
  },
  shouldForwardProp,
})

Splash.propTypes = {
  containerStyle: types.object,
  labelStyle: types.object,
  message: types.string,
  size: types.oneOf(SizeTypes),
}

Splash.displayName = 'Splash'
export default Splash

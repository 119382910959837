import { Fragment } from 'react'
import types from 'prop-types'
import { useNetworkStatus } from '../hooks/useNetworkStatus'

/**
 *
 * @param {Object} props
 * @param {React.ReactNode | React.ReactNode[]} props.children
 * @example
 *  <OfflineOnly>
 *    <div>You are offline.</div>
 *  </OfflineOnly>
 * @returns {React.ReactComponentElement<any> | null}
 *
 */
const OfflineOnly = ({ children }) => {
  const { isOnline } = useNetworkStatus()

  if (isOnline) {
    return null
  }
  return <Fragment>{children}</Fragment>
}

OfflineOnly.propTypes = {
  children: types.node.isRequired,
}

export default OfflineOnly

export const Tabs = {
  variants: {
    solid: {
      tab: {
        border: '1px',
        borderColor: 'primary.base',
        borderRadius: 'none',
        color: 'primary.base',
        minWidth: '120px',
        fontSize: 'sm',
        fontWeight: 'medium',
        textTransform: 'uppercase',
        ':first-of-type': {
          borderTopLeftRadius: 'base',
          borderBottomLeftRadius: 'base',
          borderRightWidth: 0,
        },
        ':last-of-type': {
          borderLeftWidth: 0,
          borderTopRightRadius: 'base',
          borderBottomRightRadius: 'base',
        },
        _hover: {
          opacity: 0.8,
        },
        _selected: {
          bg: 'primary.base',
          color: 'white',
        },
        _disabled: {
          ':hover': {
            opacity: 1,
          },
          color: 'gray.500',
          cursor: 'not-allowed',
          borderColor: 'gray.500',
        },
      },
    },
  },
}

/** @readonly */
export const TabVariants = [
  'solid',
  'enclosed',
  'enclosed-colored',
  'line',
  'soft-rounded',
  'solid',
  'solid-rounded',
  'unstyled',
]

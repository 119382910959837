import { theme } from '@chakra-ui/react'

export const Input = {
  sizes: {
    lg: { addon: { borderRadius: 'none' }, field: { borderRadius: 'none' } },
    md: { addon: { borderRadius: 'none' }, field: { borderRadius: 'none' } },
    sm: { addon: { borderRadius: 'none' }, field: { borderRadius: 'none' } },
    xs: { addon: { borderRadius: 'none' }, field: { borderRadius: 'none' } },
  },
  variants: {
    outline: props => {
      const defaultOutlineStyle = theme.components.Input.variants.outline(props)
      return {
        ...defaultOutlineStyle,
        field: {
          ...defaultOutlineStyle.field,
          borderColor: 'gray.300',
          _hover: {
            borderColor: 'gray.500',
          },
          '::placeholder': {
            color: 'gray.500',
          },
        },
      }
    },
  },
}

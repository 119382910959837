import { Fragment } from 'react'
import types from 'prop-types'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  useTheme,
  useToken,
} from '@chakra-ui/react'
import { Menu } from 'react-feather'

import UserMenu from './userMenu'
import OfflineStatusBar from './OfflineStatusBar'
import NotificationsDrawer from 'shared/components/NotificationsDrawer'

/**
 * @typedef {import('./HeaderProps').HeaderProps} HeaderProps
 */

/**
 * @param {HeaderProps} props
 * @returns {JSX.Element}
 */
const Header = ({
  showSidebarButton,
  dataTestId,
  onToggleSidebar,
  renderLeft,
  renderRight,
}) => {
  const { isOpen, onClose } = useDisclosure()

  const theme = useTheme()
  const separatorBorder = useToken('colors', 'gray.400')
  const minDeviceSize = useBreakpointValue({ base: 'mobile', md: 'tablet' })

  return (
    <Fragment>
      <Flex
        alignItems='center'
        as='header'
        bg='white'
        borderBottom='1px'
        borderBottomColor={separatorBorder}
        data-testid={dataTestId}
        justify='space-between'
        minHeight={theme.header.height}
        position='relative'
        px={4}
        py={4}
        width='100%'>
        <Flex flex={0.75}>
          {showSidebarButton ? (
            <Box mr={4}>
              <IconButton
                aria-label='Toggle Sidebar'
                colorScheme='blackAlpha'
                icon={
                  <Icon
                    as={Menu}
                    height={{ base: '16px', xl: '24px' }}
                    width={{ base: '16px', xl: '24px' }}
                  />
                }
                onClick={onToggleSidebar}
                variant='outline'
              />
            </Box>
          ) : null}
          {renderLeft ? (
            renderLeft()
          ) : (
            <Box
              id='header-dummy-placeholder'
              pointerEvents='none'
              visibility='hidden'
            />
          )}
        </Flex>
        <Flex alignItems='center' justifyContent='flex-end' flex={0.25}>
          {minDeviceSize === 'tablet' ? (
            <Box minWidth='240px' maxWidth='340px' mr={6} width='100%'>
              {renderRight ? renderRight() : null}
            </Box>
          ) : null}
          {/* EL-138 Disabled notifications until API is ready */}
          {/*<IconButton*/}
          {/*  aria-label='Toggle Notifications'*/}
          {/*  colorScheme='blackAlpha'*/}
          {/*  mr={4}*/}
          {/*  icon={*/}
          {/*    <Icon*/}
          {/*      as={Bell}*/}
          {/*      height={{ base: '16px', xl: '24px' }}*/}
          {/*      width={{ base: '16px', xl: '24px' }}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  onClick={onOpen}*/}
          {/*  variant='ghost'*/}
          {/*/>*/}
          <UserMenu />
        </Flex>
      </Flex>
      <OfflineStatusBar />
      <NotificationsDrawer onClose={onClose} isOpen={isOpen} />
    </Fragment>
  )
}

Header.propTypes = {
  dataTestId: types.string,
  onToggleSidebar: types.func,
  renderLeft: types.func,
  renderRight: types.func,
  showSidebarButton: types.bool,
}
Header.defaultProps = {
  dataTestId: 'header',
  showSidebarButton: false,
}

Header.displayName = 'Header'
export default Header

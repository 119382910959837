import { Suspense } from 'react'

/**
 *
 * @param {React.JSXElementConstructor<any>} Component consumes your component as such `withSuspense(MyComponent)`
 * @param {React.JSXElementConstructor<any>} Fallback provide a custom fallback component while component loading
 * @returns {React.FunctionComponent} wrapped component in `React.Suspense`
 */
export const withSuspense = (Component, Fallback = FallbackComponent) => {
  return props => (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  )
}

const FallbackComponent = () => <div>Loading...</div>

import types from 'prop-types'
import { Box, Divider, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { Mail, Smartphone } from 'react-feather'

/**
 * @typedef {import('./NotificationProps').NotificationProps} NotificationProps
 */

/**
 * @param {NotificationProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const Notification = ({ title, type, time, message, medium }) => {
  return (
    <Box
      borderWidth='1px'
      borderLeftWidth='5px'
      borderColor='gray.400'
      borderLeftColor={medium === 'phone' ? 'orange.200' : 'blue.400'}
      borderRadius='lg'
      borderLeftRadius={0}
      mt={6}
      mb={6}
      overflow='hidden'>
      <Box p={4}>
        <Flex alignItems='baseline' justifyContent='space-between' mb={2}>
          <Heading
            as='h4'
            fontSize='sm'
            fontWeight='semibold'
            lineHeight='tight'>
            {title}
          </Heading>

          <Text fontSize='sm' fontWeight='semibold'>
            {type}
          </Text>
        </Flex>

        <Flex>
          <Icon
            color={medium === 'phone' ? 'orange.200' : 'blue.400'}
            as={medium === 'phone' ? Smartphone : Mail}
            height={{ base: '16px', xl: '20px' }}
            width={{ base: '16px', xl: '20px' }}
            mr={2}
          />
          <Text fontSize='sm'>{time}</Text>
        </Flex>

        <Divider my={3} borderColor='gray.400' />

        <Text fontSize='sm'>{message}</Text>
      </Box>
    </Box>
  )
}

Notification.propTypes = {
  title: types.string.isRequired,
  type: types.string.isRequired,
  time: types.string,
  message: types.string,
  medium: types.string,
}

Notification.defaultProps = {}

export default Notification

import { Redirect, Route, Switch } from 'react-router-dom'

import { useAuth } from 'shared/hooks'

import Splash from 'components/Splash'
import { Landing, Facility, Profile } from 'screens'

import { AuthedRoute, NotFound } from './components'

const AppRouter = () => {
  const { isLoading } = useAuth()

  if (isLoading) {
    return <Splash message='Loading...' />
  }

  return (
    <Switch>
      <AuthedRoute exact path='/facilities' element={Landing} />
      <AuthedRoute path='/facilities/:facilityId' element={Facility} />
      <AuthedRoute path='/profile' element={Profile} />
      <Redirect exact from='/' to='/facilities' />
      <Route component={NotFound} />
    </Switch>
  )
}

AppRouter.displayName = 'AppRouter'
export default AppRouter

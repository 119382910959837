import types from 'prop-types'
import { useToken } from '@chakra-ui/react'
/**
 * @typedef {import('./IconProps').IconProps} IconProps
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */
const OperationsIcon = ({ isActive, ...props }) => {
  const [activeIconColor, inactiveBaseIconColor, inactiveIconColor, white] =
    useToken('colors', ['primary.base', 'gray.700', 'gray.400', 'white'])

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26 26'
      fill='none'
      {...props}>
      <path
        clipRule='evenodd'
        d='M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z'
        fill={isActive ? activeIconColor : 'none'}
        stroke={isActive ? white : inactiveIconColor}
      />
      <path
        clipRule='evenodd'
        d='M13 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        clipRule='evenodd'
        d='M17.933 15a1.1 1.1 0 0 0 .22 1.213l.04.04a1.333 1.333 0 1 1-1.887 1.887l-.04-.04a1.1 1.1 0 0 0-1.213-.22 1.1 1.1 0 0 0-.667 1.007V19a1.333 1.333 0 1 1-2.666 0v-.06a1.1 1.1 0 0 0-.72-1.007 1.1 1.1 0 0 0-1.213.22l-.04.04a1.334 1.334 0 1 1-1.887-1.887l.04-.04a1.1 1.1 0 0 0 .22-1.213 1.1 1.1 0 0 0-1.007-.667H7a1.333 1.333 0 0 1 0-2.666h.06A1.1 1.1 0 0 0 8.066 11a1.1 1.1 0 0 0-.22-1.213l-.04-.04A1.333 1.333 0 1 1 9.694 7.86l.04.04a1.1 1.1 0 0 0 1.213.22H11a1.1 1.1 0 0 0 .666-1.007V7a1.333 1.333 0 0 1 2.667 0v.06A1.1 1.1 0 0 0 15 8.066a1.1 1.1 0 0 0 1.213-.22l.04-.04a1.333 1.333 0 1 1 1.887 1.887l-.04.04a1.1 1.1 0 0 0-.22 1.213V11a1.1 1.1 0 0 0 1.007.666H19a1.333 1.333 0 1 1 0 2.667h-.06a1.1 1.1 0 0 0-1.007.667Z'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

OperationsIcon.propTypes = {
  isActive: types.bool,
}
OperationsIcon.defaultProps = {
  isActive: false,
}

OperationsIcon.displayName = 'OperationsIcon'
export default OperationsIcon

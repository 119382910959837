import { useLayoutEffect } from 'react'
import types from 'prop-types'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useToast } from 'shared/hooks'

/**
 * @typedef {import('./RouteProps').GuardedRouteProps} GuardedRouteProps
 * @param {GuardedRouteProps} props
 * @returns {JSX.Element | null}
 */
const GuardedRoute = ({ children, element: Element, exact, path, guards }) => {
  const location = useLocation()
  const toast = useToast()
  const mayAccess = guards.every(
    /** @param {boolean} guard */ guard => guard === true
  )

  useLayoutEffect(() => {
    if (!mayAccess) {
      toast.show({
        title: 'Forbidden',
        description: 'You are not allowed to access this resource',
        status: 'error',
      })
    }
  }, [mayAccess, toast])

  if (!mayAccess) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    )
  }

  if (!children && !Element) {
    console.error(
      '* Please provide "children" or "element" prop to GuardedRoute'
    )
    return null
  }

  if (children) {
    return (
      <Route exact={exact} path={path}>
        {children}
      </Route>
    )
  }

  return <Route exact={exact} path={path} component={Element} />
}

GuardedRoute.propTypes = {
  children: types.node,
  element: types.object,
  exact: types.bool,
  path: types.string.isRequired,
  guards: types.arrayOf(types.bool).isRequired,
}
GuardedRoute.defaultProps = {
  exact: false,
}

GuardedRoute.displayName = 'GuardedRoute'
export default GuardedRoute

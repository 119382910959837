import { forwardRef } from 'react'
import ReactSelect from 'react-select'
import AsyncReactSelect from 'react-select/async'
import CreatableReactSelect from 'react-select/creatable'

import ChakraReactSelect from './ChakraReactSelect'

// inspired by https://codesandbox.io/s/648uv?file=/example.js

/**
 * @typedef {import('./SelectProps').SelectProps} SelectProps
 * @typedef {import('./SelectProps').AsyncSelectProps} AsyncSelectProps
 * @typedef {import('./SelectProps').CreatableSelectProps} CreatableSelectProps
 */

const Select = forwardRef(
  /**
   *
   * @param {SelectProps} props
   * @param {React.ForwardedRef<any>} ref
   * @returns {JSX.Element}
   */
  (props, ref) => (
    <ChakraReactSelect {...props}>
      <ReactSelect ref={ref} />
    </ChakraReactSelect>
  )
)
Select.displayName = 'Select'

const AsyncSelect = forwardRef(
  /**
   * @param {AsyncSelectProps} props
   * @param {React.ForwardedRef<any>} ref
   * @returns {JSX.Element}
   */
  (props, ref) => (
    <ChakraReactSelect {...props}>
      <AsyncReactSelect ref={ref} />
    </ChakraReactSelect>
  )
)
AsyncSelect.displayName = 'AsyncSelect'

const CreatableSelect = forwardRef(
  /**
   *
   * @param {CreatableSelectProps} props
   * @param {React.ForwardedRef<any>} ref
   * @returns {JSX.Element}
   */
  (props, ref) => (
    <ChakraReactSelect {...props}>
      <CreatableReactSelect ref={ref} />
    </ChakraReactSelect>
  )
)
CreatableSelect.displayName = 'CreatableSelect'

export { Select as default, AsyncSelect, CreatableSelect }

import types from 'prop-types'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import { useController, useFormContext } from 'react-hook-form'
import { ChevronUp, ChevronDown } from 'react-feather'

/**
 * @typedef {import('./FormProps').FormNumberProps} FormNumberProps
 */

/**
 * @param {FormNumberProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const FormNumber = ({
  autoComplete,
  className,
  clampValueOnBlur,
  fontSize,
  helperText,
  isDisabled,
  inputMode,
  isReadOnly,
  keepWithinRange,
  label,
  min,
  max,
  name,
  placeholder,
  precision,
  step,
  type,
  size,
  variant,
  ...rest
}) => {
  const {
    control,
    formState,
    // @ts-ignore
    initialValues,
    // @ts-ignore
    isInitializing,
    // @ts-ignore
    validationSchema,
  } = useFormContext()
  const defaultValue = initialValues[name] ?? 0
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ name, control, defaultValue })

  const error = formState.errors[name]
  const isRequired = validationSchema?.fields[name]?.exclusiveTests?.required

  return (
    <FormControl
      className={className}
      isDisabled={isDisabled || isInitializing}
      isRequired={isRequired}
      isInvalid={error?.message.length > 0}
      isReadOnly={isReadOnly}
      label={label}
      size={size}
      variant={variant}
      {...rest}>
      {label ? (
        <FormLabel
          color='gray.500'
          fontSize='xs'
          fontWeight='normal'
          htmlFor={name}>
          {label}
        </FormLabel>
      ) : null}
      <NumberInput
        aria-invalid={error?.message ? 'true' : undefined}
        aria-required={isRequired}
        clampValueOnBlur={clampValueOnBlur}
        defaultValue={defaultValue}
        inputMode={inputMode}
        isDisabled={isDisabled}
        fontSize={fontSize}
        keepWithinRange={keepWithinRange}
        min={min}
        max={max}
        onBlur={onBlur}
        onChange={value => onChange(parseInt(value, 10))}
        precision={precision}
        ref={ref}
        step={step}
        value={value != null ? String(value) : undefined}>
        <NumberInputField placeholder={placeholder} />
        <NumberInputStepper>
          <NumberIncrementStepper as={ChevronUp} color='info.base' />
          <NumberDecrementStepper as={ChevronDown} color='info.base' />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage fontSize='xs' fontWeight='normal' role='alert'>
        {error?.message}
      </FormErrorMessage>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}

FormNumber.propTypes = {
  autoComplete: types.string,
  className: types.string,
  clampValueOnBlur: types.bool,
  fontSize: types.oneOfType([types.string, types.object]),
  helperText: types.string,
  isDisabled: types.bool,
  inputMode: types.oneOf([
    'search',
    'text',
    'none',
    'tel',
    'url',
    'email',
    'numeric',
    'decimal',
  ]),
  isReadOnly: types.bool,
  keepWithinRange: types.bool,
  label: types.string,
  min: types.number,
  max: types.number,
  name: types.string.isRequired,
  placeholder: types.string,
  precision: types.number,
  step: types.number,
  size: types.string,
  type: types.string,
  variant: types.string,
}

FormNumber.defaultProps = {
  clampValueOnBlur: false,
  inputMode: 'decimal',
  keepWithinRange: false,
}

FormNumber.displayName = 'FormNumber'
export default FormNumber

import types from 'prop-types'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import {
  Flex,
  Icon as ChakraIcon,
  Link,
  Text,
  useToken,
} from '@chakra-ui/react'

import { useAuth, useStackNavigation } from 'shared/hooks'

/**
 * @typedef {import('./SidebarProps').SidebarContentProps} SidebarContentProps
 * @typedef {import('./SidebarProps').MenuLinkProps} MenuLinkProps
 */

/**
 * @param {SidebarContentProps} props
 * @returns {JSX.Element}
 */
const SidebarContent = ({ routes, variant }) => {
  const sidebarRef = useStackNavigation({ direction: 'vertical' })
  const { url } = useRouteMatch()
  const { user } = useAuth()

  return (
    <Flex alignItems='center' flexDirection='column' ref={sidebarRef}>
      {routes.map(route => (
        <MenuLink
          key={route.id}
          Icon={route.Icon}
          isVisible={
            route.requiredRoles.length > 0
              ? user.hasRoles(route.requiredRoles)
              : true
          }
          to={`${url}/${route.path}`}
          title={route.name}
          variant={variant}
        />
      ))}
    </Flex>
  )
}

/**
 * @param {MenuLinkProps} props
 * @returns {JSX.Element}
 */
const MenuLink = ({ exact, Icon, isVisible, title, to, variant }) => {
  const location = useLocation()
  const isActive = exact
    ? location.pathname === to
    : location.pathname.includes(to)
  const [activeLinkColor, inactiveLinkColor] = useToken('colors', [
    'info.base',
    'neutral.black',
  ])

  // TODO: update with the new API when react-router-dom stable version gets released
  // https://github.com/remix-run/react-router/releases/tag/v6.0.0-beta.3
  const routeActiveStyle = {
    borderLeft: variant === 'sidebar' ? `2px solid ${activeLinkColor}` : 'none',
    color: activeLinkColor,
  }

  if (!isVisible) {
    return null
  }

  return (
    <Link
      activeStyle={routeActiveStyle}
      as={NavLink}
      borderLeft={variant === 'sidebar' ? '2px' : 'none'}
      borderLeftColor={variant === 'sidebar' ? 'transparent' : 'none'}
      color={inactiveLinkColor}
      exact={exact}
      mt={6}
      mb={2}
      to={{ pathname: to, state: location.state }}
      width='100%'>
      <Flex
        alignItems='center'
        direction='column'
        justifyContent='center'
        pl={2}
        pr={1}>
        <ChakraIcon as={Icon} isActive={isActive} height='32px' width='32px' />
        <Text
          color='gray.900'
          fontSize='xs'
          isTruncated
          letterSpacing='wider'
          mt={2}
          textAlign='center'
          textTransform='uppercase'
          title={title}
          width='100%'
          whiteSpace='nowrap'>
          {title}
        </Text>
      </Flex>
    </Link>
  )
}

MenuLink.propTypes = {
  exact: types.bool,
  Icon: types.func.isRequired,
  isVisible: types.bool,
  title: types.string.isRequired,
  to: types.string.isRequired,
  variant: types.oneOf(['drawer', 'sidebar']),
}

SidebarContent.propTypes = {
  routes: types.arrayOf(types.shape({})),
  variant: types.oneOf(['drawer', 'sidebar']),
}

SidebarContent.displayName = 'SidebarContent'
export default SidebarContent

// @ts-nocheck
import types from 'prop-types'
import {
  Flex,
  Icon,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  CloseButton,
  Center,
  Box,
  Portal,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/react'
import { components as SelectComponents } from 'react-select'
import { ChevronDown } from 'react-feather'

// #region Control components
export const Control = ({
  children,
  innerRef,
  innerProps,
  isDisabled,
  isFocused,
  selectProps: { size, isInvalid },
}) => {
  const inputStyles = useMultiStyleConfig('Input', { size })

  const heights = {
    sm: 8,
    md: 10,
    lg: 12,
  }

  return (
    <StylesProvider value={inputStyles}>
      <Flex
        ref={innerRef}
        sx={{
          ...inputStyles.field,
          bg: 'transparent',
          borderColor: 'gray.300',
          borderRadius: 'none',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          height: 'auto',
          overflow: 'hidden',
          minHeight: heights[size],
          padding: 0,
          _hover: {
            borderColor: 'gray.500',
          },
        }}
        {...innerProps}
        data-focus={isFocused ? true : undefined}
        data-invalid={isInvalid ? true : undefined}
        data-disabled={isDisabled ? true : undefined}>
        {children}
      </Flex>
    </StylesProvider>
  )
}

export const MultiValueContainer = ({
  children,
  innerRef,
  innerProps,
  data,
  selectProps,
}) => {
  const passedVariant = data.variant ?? selectProps.variant
  // react-select Fixed Options example: https://react-select.com/home#fixed-options
  const derivedVariant = data.isFixed ? 'solid' : 'subtle'
  const colorScheme = data.colorScheme ?? selectProps.colorScheme ?? 'gray'
  return (
    <Tag
      {...innerProps}
      ref={innerRef}
      colorScheme={colorScheme}
      m='0.125rem'
      size={selectProps.size}
      variant={passedVariant ?? derivedVariant}>
      {children}
    </Tag>
  )
}
export const MultiValueLabel = ({ children, innerRef, innerProps }) => (
  <TagLabel ref={innerRef} {...innerProps}>
    {children}
  </TagLabel>
)
export const MultiValueRemove = ({
  children,
  innerRef,
  innerProps,
  data: { isFixed },
}) => {
  if (isFixed) {
    return null
  }

  return (
    <TagCloseButton ref={innerRef} {...innerProps} tabIndex={-1}>
      {children}
    </TagCloseButton>
  )
}

export const IndicatorSeparator = ({ innerProps }) => (
  <Divider {...innerProps} orientation='vertical' opacity='1' />
)

export const ClearIndicator = ({ innerProps, selectProps: { size } }) => (
  <CloseButton {...innerProps} size={size} mx={2} tabIndex={-1} />
)

export const DropdownIndicator = ({
  innerProps,
  selectProps: { menuIsOpen, size },
}) => {
  const { addon } = useStyles()

  const iconSizes = {
    sm: 2,
    md: 4,
    lg: 5,
  }
  const iconSize = iconSizes[size]

  return (
    <Center
      {...innerProps}
      sx={{
        ...addon,
        bg: menuIsOpen ? 'info.400' : 'transparent',
        borderRadius: 'none',
        borderWidth: 0,
        color: 'white',
        cursor: 'pointer',
        height: '100%',
      }}>
      <Icon
        as={ChevronDown}
        color={menuIsOpen ? 'white' : 'info.400'}
        height={iconSize}
        transform={menuIsOpen ? 'rotate(180deg)' : 'none'}
        transition='transform 150ms ease-in-out'
        width={iconSize}
      />
    </Center>
  )
}
// #endregion

// #region Menu components
export const MenuPortal = ({ children }) => <Portal>{children}</Portal>

export const Menu = ({ children, ...props }) => {
  const menuStyles = useMultiStyleConfig('Menu')
  return (
    <SelectComponents.Menu {...props}>
      <StylesProvider value={menuStyles}>{children}</StylesProvider>
    </SelectComponents.Menu>
  )
}

export const MenuList = ({ innerRef, children, maxHeight }) => {
  const { list } = useStyles()

  return (
    <Box
      sx={{
        ...list,
        borderRadius: 'none',
        maxHeight: `${maxHeight}px`,
        overflowY: 'auto',
      }}
      ref={innerRef}>
      {children}
    </Box>
  )
}

export const GroupHeading = ({ innerProps, children }) => {
  const { groupTitle } = useStyles()
  return (
    <Box sx={groupTitle} {...innerProps}>
      {children}
    </Box>
  )
}

export const Option = ({
  children,
  innerProps,
  innerRef,
  isDisabled,
  isFocused,
  isSelected,
  selectProps: { size },
}) => {
  const { item } = useStyles()
  const optionBackground = (() => {
    if (isSelected) {
      return item._selected.bg
    }
    if (isFocused) {
      return item._focus.bg
    }
    return 'transparent'
  })()
  return (
    <Box
      role='button'
      disabled={isDisabled}
      ref={innerRef}
      sx={{
        ...item,
        bg: optionBackground,
        ':not(:first-of-type)': {
          borderTop: '1px',
          borderColor: 'gray.300',
        },
        fontSize: size,
        textAlign: 'left',
        width: '100%',
        ...(isDisabled ? item._disabled : {}),
      }}
      {...innerProps}>
      {children}
    </Box>
  )
}
// #endregion

Control.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]).isRequired,
  innerRef: types.func,
  innerProps: types.object,
  isDisabled: types.bool,
  isFocused: types.bool,
  selectProps: types.shape({ size: types.string, isInvalid: types.bool }),
}
MultiValueContainer.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  innerRef: types.func,
  innerProps: types.object,
  data: types.shape({
    colorScheme: types.string,
    isFixed: types.bool,
    variant: types.string,
  }),
  selectProps: types.shape({
    colorScheme: types.string,
    size: types.string,
    variant: types.string,
  }),
}
MultiValueLabel.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  innerRef: types.func,
  innerProps: types.object,
}
MultiValueRemove.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  innerRef: types.func,
  innerProps: types.object,
  data: types.shape({ isFixed: types.bool }),
}
IndicatorSeparator.propTypes = {
  innerProps: types.object,
}
ClearIndicator.propTypes = {
  innerProps: types.object,
  selectProps: types.shape({ size: types.string }),
}
DropdownIndicator.propTypes = {
  innerProps: types.object,
  selectProps: types.shape({ menuIsOpen: types.bool, size: types.string }),
  isFocused: types.bool,
}
MenuPortal.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
}
Menu.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  theme: types.shape({
    borderRadius: types.number,
  }),
}
MenuList.propTypes = {
  innerRef: types.func,
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  maxHeight: types.oneOfType([
    types.number,
    types.string,
    types.objectOf(types.string),
    types.arrayOf(types.string),
  ]),
  selectProps: types.shape({ size: types.string }),
}
GroupHeading.propTypes = {
  innerProps: types.object,
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
}
Option.propTypes = {
  innerRef: types.func,
  innerProps: types.object,
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  isFocused: types.bool,
  isSelected: types.bool,
  isDisabled: types.bool,
  selectProps: types.shape({ size: types.string }),
}

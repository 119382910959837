import types from 'prop-types'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'

// InfoOutlineIcon

const NoData = ({ title, message }) => {
  return (
    <Alert
      status='info'
      variant='subtle'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      height='200px'
      background='transparent'
      mt={8}>
      <AlertIcon boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={1} fontSize='lg'>
        {title}
      </AlertTitle>
      {message ?? <AlertDescription maxWidth='sm'>{message}</AlertDescription>}
    </Alert>
  )
}

NoData.propTypes = {
  title: types.string.isRequired,
  message: types.string,
}

export default NoData

import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Box, Flex, Icon, Link, useTheme } from '@chakra-ui/react'
import { ArrowLeft } from 'react-feather'

import { Header, SelectFacility } from 'shared/components'
import Sidebar, { useSidebarVariants } from 'shared/components/sidebar'
import routes from './routes'

const Profile = () => {
  const history = useHistory()
  const { state } = useLocation()
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const variants = useSidebarVariants()
  const { path, url } = useRouteMatch()
  const {
    header: { height: headerHeight },
    mainContainer: { paddingLeft, paddingTop },
  } = useTheme()

  const toggleSidebar = () => setSidebarOpen(prevState => !prevState)

  const mainRoute = routes.find(route => route.requiredRoles.length === 0)

  // @ts-ignore
  const previousScreen = state != null ? state.from : '/'
  return (
    <Flex height='100%' width='100%'>
      <Helmet>
        <title>Warehousing - Profile</title>
        <meta name='description' content='Ndustrial.io warehousing' />
      </Helmet>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
        routes={routes}
        variant={variants?.navigation}
      />
      <Box height='100%' width='100%'>
        <Header
          dataTestId='profile-header'
          onToggleSidebar={toggleSidebar}
          showSidebarButton={variants?.navigationButton}
          renderLeft={() => (
            <Link
              as={RouterLink}
              to={previousScreen}
              alignItems='center'
              display='flex'
              px={2}
              py={1}>
              <Icon as={ArrowLeft} boxSize={5} mr={3} />
              Go Back
            </Link>
          )}
          renderRight={() => <SelectFacility />}
        />
        <Box
          as='main'
          height={`calc(100vh - ${headerHeight})`}
          overflowY='auto'
          pl={paddingLeft}
          pt={paddingTop}>
          <Switch>
            {routes.map(({ Component, id, path: routePath }) => (
              <Route
                component={Component}
                key={id}
                path={`${path}/${routePath}`}
              />
            ))}
            <Redirect
              from={path}
              to={{
                pathname: `${url}/${mainRoute?.path}`,
                state: history.location.state,
              }}
            />
          </Switch>
        </Box>
      </Box>
    </Flex>
  )
}

Profile.displayName = 'Profile'
export default Profile

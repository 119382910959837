import types from 'prop-types'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react'
import { Badge } from 'shared/components/index'
import Notification from 'shared/components/Notification'

/**
 * @typedef {import('./NotificationsDrawerProps').NotificationsDrawerProps} NotificationsDrawerProps
 */

/**
 * @param {NotificationsDrawerProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const NotificationsDrawer = ({ onClose, isOpen }) => {
  const notifications = [
    {
      id: 1,
      title: 'Oxnard',
      type: 'Rate Avoidance',
      time: '54 Minutes Ago',
      message: 'Suggested to shed load today from 5p - 7p',
      medium: 'phone',
    },
    {
      id: 2,
      title: 'Mira Loma',
      type: 'Day-Ahead Rate Reports',
      time: '7:30am Today',
      message: 'Rate Report Sent',
      medium: 'email',
    },
  ]

  return (
    <Drawer placement='right' onClose={onClose} isOpen={isOpen} size='md'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth='1px'>
          <Flex>
            <Box mr={2}>Notifications</Box>
            <Badge
              colorScheme='info'
              fontSize='sm'
              label={`${notifications.length}`}
              px={2}
              py={0}
              variant='solid'
            />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {notifications.map(notification => (
            <Notification {...notification} key={notification.id} />
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

NotificationsDrawer.propTypes = {
  onClose: types.func.isRequired,
  isOpen: types.bool.isRequired,
}

export default NotificationsDrawer

import types from 'prop-types'
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
} from '@chakra-ui/react'

import SidebarContent from './SidebarContent'
import { SelectFacility } from 'shared/components'

/**
 * @typedef {import('./SidebarProps').SidebarProps} SidebarProps
 */

/**
 * @param {SidebarProps} props
 * @returns {JSX.Element}
 */
const Sidebar = ({ isOpen, onClose, routes, variant }) => {
  return variant === 'sidebar' ? (
    <Box
      as='aside'
      bg='white'
      borderRight='1px'
      borderRightColor='gray.400'
      flexDirection='column'
      height='100%'
      minHeight='100vh'
      width='100px'>
      <SidebarContent routes={routes} variant='sidebar' />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Nsight Warehousing</DrawerHeader>
          <DrawerBody>
            <SelectFacility />
            <SidebarContent routes={routes} variant='drawer' />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

Sidebar.propTypes = {
  isOpen: types.bool,
  onClose: types.func,
  routes: types.arrayOf(types.object),
  variant: types.string,
}

Sidebar.displayName = 'Sidebar'
export { useSidebarVariants } from './useSidebarVariants'
export default Sidebar

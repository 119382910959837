import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Box, Flex, useDisclosure, useTheme } from '@chakra-ui/react'

import Sidebar, { useSidebarVariants } from 'shared/components/sidebar'
import { Header, HeaderNavLinks, SelectFacility } from 'shared/components'
import routes from './routes'
import { useAuth } from 'shared/hooks'

/**
 * @returns {JSX.Element}
 */
const FacilityRouter = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { path, url } = useRouteMatch()
  const variants = useSidebarVariants()
  const { user } = useAuth()

  const {
    header: { height: headerHeight },
    mainContainer: { paddingLeft, paddingTop },
  } = useTheme()

  const mainRoute = routes.find(
    route =>
      route.requiredRoles.length === 0 || user.hasRoles(route.requiredRoles)
  )

  return (
    <Flex height='100%' width='100%'>
      <Sidebar
        isOpen={isOpen}
        onClose={onClose}
        routes={routes}
        variant={variants?.navigation}
      />
      <Box height='100%' width='100%'>
        <Header
          onToggleSidebar={onToggle}
          showSidebarButton={variants?.navigationButton}
          renderLeft={() => <HeaderNavLinks routes={routes} />}
          renderRight={() => <SelectFacility />}
        />
        <Box
          as='main'
          height={`calc(100vh - ${headerHeight})`}
          overflowY='auto'
          pl={paddingLeft}
          pt={paddingTop}>
          <Switch>
            {routes.map(({ Component, id, path: routePath }) => (
              <Route
                component={Component}
                key={id}
                path={`${path}/${routePath}`}
              />
            ))}
            <Redirect from={path} to={`${url}/${mainRoute?.path}`} />
          </Switch>
        </Box>
      </Box>
    </Flex>
  )
}

FacilityRouter.propTypes = {}

FacilityRouter.displayName = 'FacilityRouter'
export default FacilityRouter

import types from 'prop-types'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Link } from '@chakra-ui/react'

/**
 *
 * @param {Object} props
 * @param {string} props.returnTo
 * @returns
 */
const NotFound = ({ returnTo }) => {
  return (
    <div className='not-found'>
      <h1>404</h1>
      <h2>Page not found!</h2>
      <p>
        <Link as={ReactRouterLink} to={returnTo}>
          Go back to the main page
        </Link>
      </p>
    </div>
  )
}

NotFound.propTypes = {
  returnTo: types.string,
}
NotFound.defaultProps = {
  returnTo: '/',
}

NotFound.displayName = 'NotFound'
export default NotFound

import types from 'prop-types'
import { useToken } from '@chakra-ui/react'
/**
 * @typedef {import('./IconProps').IconProps} IconProps
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */
const ProfileIcon = ({ isActive, ...props }) => {
  const [activeIconColor, inactiveBaseIconColor, inactiveIconColor, white] =
    useToken('colors', ['primary.base', 'gray.700', 'gray.400', 'white'])

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26 26'
      fill='none'
      {...props}>
      <path
        clipRule='evenodd'
        d='M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z'
        fill={isActive ? activeIconColor : 'none'}
        stroke={isActive ? white : inactiveIconColor}
      />
      <path
        clipRule='evenodd'
        d='M 19.873 19.639 L 19.873 18.124 C 19.873 16.45 18.345 15.092 16.459 15.092 L 9.633 15.092 C 7.748 15.092 6.22 16.45 6.22 18.124 L 6.22 19.639'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        clipRule='evenodd'
        cx='13'
        cy='7'
        r='4'
        stroke={isActive ? white : inactiveBaseIconColor}
        strokeLinecap='round'
        strokeLinejoin='round'
        transform='matrix(0.798969, 0, 0, 0.780699, 2.718151, 2.660555)'
      />
    </svg>
  )
}

ProfileIcon.propTypes = {
  isActive: types.bool,
}
ProfileIcon.defaultProps = {
  isActive: false,
}

ProfileIcon.displayName = 'ProfileIcon'
export default ProfileIcon

import theme from 'assets/theme'

/**
 * @readonly
 * @enum {string}
 */
const BorderTypes = Object.keys(theme.borders)
/**
 * @readonly
 * @enum {string}
 */
const ColorVariants = Object.keys(theme.colors)
/**
 * @readonly
 * @enum {string}
 */
const FontSizeTypes = Object.keys(theme.fontSizes)
/**
 * @readonly
 * @enum {string}
 */
const FontWeightTypes = Object.keys(theme.fontWeights)
/**
 * @readonly
 * @enum {string}
 */
const FontTypes = Object.keys(theme.fonts)
/**
 * @readonly
 * @enum {string}
 */
const RadiiTypes = Object.keys(theme.radii)
/**
 * @readonly
 * @enum {string}
 */
const ShadowTypes = Object.keys(theme.shadows)
/**
 * @reaodnly
 * @enum {string}
 */
const SizeTypes = Object.keys(theme.sizes)
/**
 * @readonly
 * @enum {string}
 */
const TransitionTypes = Object.keys(theme.transition)
/**
 * @readonly
 * @enum {string}
 */
const ZIndicesTypes = Object.keys(theme.zIndices)

export {
  BorderTypes,
  ColorVariants,
  FontSizeTypes,
  FontWeightTypes,
  FontTypes,
  RadiiTypes,
  ShadowTypes,
  SizeTypes,
  TransitionTypes,
  ZIndicesTypes,
}

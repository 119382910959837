import { Collapse, HStack, Icon, Text } from '@chakra-ui/react'
import { WifiOff } from 'react-feather'
import { useNetworkStatus } from 'shared/hooks/useNetworkStatus'

import OfflineOnly from '../OfflineOnly'

const OfflineBar = () => {
  const { isOnline } = useNetworkStatus()
  return (
    <Collapse animateOpacity unmountOnExit in={!isOnline}>
      <OfflineOnly>
        <HStack
          alignItems='center'
          bg='danger.500'
          height='36px'
          justifyContent='center'
          spacing={3}
          transition='height 300ms ease-in-out'>
          <Icon as={WifiOff} color='white' height='20px' width='20px' />
          <Text color='white' fontSize='md'>
            Looks like your internet connection is down. Waiting to reconnect...
          </Text>
        </HStack>
      </OfflineOnly>
    </Collapse>
  )
}

OfflineBar.propTypes = {}

export default OfflineBar

import { useEffect, useRef, useState } from 'react'
import types from 'prop-types'
import { Badge, Box, Heading } from '@chakra-ui/react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'

import { ColorVariants } from 'shared/nio-types'
import './assets/ag-style.scss'

/**
 * @typedef {import('./AgGridProps').AgGridProps} AgGridProps
 */

/**
 * @param {AgGridProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const NioAgGrid = ({
  badgeColor,
  columns,
  defaultColDef,
  height,
  isLoading,
  loadingMessage,
  noDataMessage,
  pagination,
  paginationPageSize,
  rowData,
  rowHeight,
  showNumOfItems,
  suppressKeyboardEvent,
  title,
  width,
  ...props
}) => {
  const [{ grid: gridApi }, setGridApi] = useState({ grid: null, column: null })
  // use templates instead of React components due to ag-grid react issue
  // with programatically toggling the overlay
  const {
    current: { loadingTemplate, noDataTemplate },
  } = useRef({
    loadingTemplate: `<span>${loadingMessage}</span>`,
    noDataTemplate: `<span>${noDataMessage}</span>`,
  })

  /**
   *
   * @param {Object} params
   * @param {Object} params.api
   * @param {Object} params.columnApi
   */
  const handleGridReady = params => {
    setGridApi({ grid: params.api, column: params.columnApi })
  }

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay()
      } else {
        if (!rowData || rowData.length === 0) {
          gridApi.showNoRowsOverlay()
        } else {
          gridApi.hideOverlay()
        }
      }
    }
  }, [isLoading, gridApi, rowData])

  return (
    <Box className='ag-theme-alpine' height={height} width={width} {...props}>
      {title ? (
        <Heading as='h2' fontSize='xl' fontWeight='medium' mb={4} px={4}>
          {title}{' '}
          {showNumOfItems ? (
            <Badge
              borderRadius='full'
              colorScheme={badgeColor}
              fontSize='sm'
              fontWeight='medium'
              ml={2}
              px={2}
              py={1}
              variant='outline'>
              {rowData ? rowData.length : 0}
            </Badge>
          ) : null}
        </Heading>
      ) : null}
      <AgGridReact
        defaultColDef={defaultColDef}
        onGridReady={handleGridReady}
        overlayLoadingTemplate={loadingTemplate}
        overlayNoRowsTemplate={noDataTemplate}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        rowData={rowData}
        suppressKeyboardEvent={suppressKeyboardEvent}
        rowHeight={rowHeight}>
        {columns.map(({ headerName, headerClass, field, ...other }, ix) => {
          return (
            <AgGridColumn
              headerName={headerName}
              headerClass={headerClass}
              key={field ?? `$column-${ix}`}
              field={field}
              {...other}
            />
          )
        })}
      </AgGridReact>
    </Box>
  )
}

NioAgGrid.propTypes = {
  badgeColor: types.oneOf(ColorVariants),
  columns: types.arrayOf(
    types.shape({
      headerName: types.string,
      field: types.string,
      cellClass: types.oneOfType([types.string, types.arrayOf(types.string)]),
      comparator: types.func,
      filter: types.string,
      floatingFilter: types.bool,
      sort: types.oneOf(['asc', 'desc']),
      sortable: types.bool,
      sortingOrder: types.arrayOf(types.oneOf(['asc', 'desc'])),
    })
  ),
  defaultColDef: types.object,
  height: types.oneOfType([types.number, types.string, types.object]),
  isLoading: types.bool,
  loadingMessage: types.string,
  noDataMessage: types.string,
  pagination: types.bool,
  paginationPageSize: types.number,
  rowData: types.array,
  rowHeight: types.number,
  showNumOfItems: types.bool,
  suppressKeyboardEvent: types.func,
  title: types.string,
  width: types.oneOfType([types.number, types.string, types.object]),
}
NioAgGrid.defaultProps = {
  badgeColor: 'info',
  defaultColDef: {
    flex: 1,
    minWidth: 100,
    sortable: true,
  },
  loadingMessage: 'Loading...',
  noDataMessage: 'No data to show',
  pagination: false,
  paginationPageSize: 10,
  rowData: [],
  rowHeight: 28,
  showNumOfItems: false,
  height: '30vh',
  width: '100%',
}

NioAgGrid.displayName = 'NioAgGrid'
export default NioAgGrid

import types from 'prop-types'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

/**
 * @typedef {import('./FormProps').FormFieldProps} FormFieldProps
 */

/**
 * @param {FormFieldProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const FormField = ({
  autoComplete,
  className,
  helperText,
  isDisabled,
  isReadOnly,
  label,
  name,
  placeholder,
  renderLeft,
  renderRight,
  type,
  size,
  variant,
  ...rest
}) => {
  const {
    formState,
    register,
    /* @ts-ignore */
    initialValues,
    /* @ts-ignore */
    isInitializing,
    /* @ts-ignore */
    validationSchema,
  } = useFormContext()
  const { onBlur, onChange, ref } = register(name)

  const error = formState.errors[name]
  const isRequired = validationSchema?.fields[name]?.exclusiveTests?.required

  return (
    <FormControl
      className={className}
      defaultValue={initialValues[name] ?? ''}
      isDisabled={isDisabled || isInitializing}
      isRequired={isRequired}
      isInvalid={error?.message.length > 0}
      isReadOnly={isReadOnly}
      label={label}
      size={size}
      variant={variant}
      {...rest}>
      {label ? (
        <FormLabel
          color='gray.700'
          fontSize='xs'
          fontWeight='normal'
          htmlFor={name}>
          {label}
        </FormLabel>
      ) : null}
      <InputGroup>
        {renderLeft ? <InputLeftAddon>{renderLeft()}</InputLeftAddon> : null}
        <Input
          aria-invalid={error?.message ? 'true' : undefined}
          aria-required={isRequired}
          autoComplete={autoComplete}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          type={type}
        />
        {renderRight ? (
          <InputRightAddon>{renderRight()}</InputRightAddon>
        ) : null}
      </InputGroup>
      <FormErrorMessage fontSize='xs' fontWeight='normal' role='alert'>
        {error?.message}
      </FormErrorMessage>
      {helperText ? (
        <FormHelperText fontSize='xs' fontWeight='normal'>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

FormField.propTypes = {
  autoComplete: types.string,
  className: types.string,
  helperText: types.string,
  isDisabled: types.bool,
  isReadOnly: types.bool,
  label: types.string,
  name: types.string.isRequired,
  placeholder: types.string,
  renderLeft: types.func,
  renderRight: types.func,
  size: types.string,
  type: types.string,
  variant: types.string,
}

FormField.defaultProps = {
  autoComplete: 'off',
  isDisabled: false,
  isReadOnly: false,
  label: null,
  placeholder: 'Start typing',
  type: 'text',
}

FormField.displayName = 'FormField'
export default FormField

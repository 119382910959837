import { useCallback, useEffect, useRef } from 'react'
import types from 'prop-types'
import { Prompt } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import isEqual from 'lodash/isEqual'

/**
 * @typedef {import('./FormProps').FormProps} FormProps
 * @typedef {import('react-hook-form').UseFormReturn} FormContext
 */

/**
 * @param {FormProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const Form = ({
  children,
  initialValues,
  isInitializing,
  onSubmit,
  validationSchema,
  ...props
}) => {
  const previousInitialData = useRef(initialValues)

  const formContext = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!isEqual(previousInitialData.current, initialValues)) {
      formContext.reset(initialValues)
      previousInitialData.current = initialValues
    }
  }, [initialValues, formContext])

  const _renderFormChildren = useCallback(
    /** @param {((formContext: FormContext) => React.ReactNode) | React.ReactNode} children */ children => {
      if (typeof children === 'function') {
        const formChildren = children(formContext)
        return formChildren
      }

      return children
    },
    [formContext]
  )

  const extendedFormContext = Object.assign({}, formContext, {
    initialValues,
    isInitializing,
    validationSchema,
  })
  return (
    <FormProvider {...extendedFormContext}>
      <form
        onSubmit={formContext.handleSubmit(data =>
          onSubmit(data, {
            clearErrors: formContext.clearErrors,
            reset: formContext.reset,
            setError: formContext.setError,
            setValue: formContext.setValue,
          })
        )}
        noValidate
        {...props}>
        {_renderFormChildren(children)}
        <Prompt
          message='You have unsaved changes. Are you sure you wish to leave?'
          when={formContext.formState.isDirty}
        />
      </form>
    </FormProvider>
  )
}

Form.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node, types.func])
    .isRequired,
  isInitializing: types.bool,
  initialValues: types.object,
  onSubmit: types.func.isRequired,
  validationSchema: types.object,
}
Form.defaultProps = {
  initialValues: {},
  isInitializing: false,
}

Form.displayName = 'Form'
export default Form

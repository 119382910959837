import types from 'prop-types'
import { HStack, IconButton } from '@chakra-ui/react'

/**
 * @typedef {Object} Action
 * @property {string} ariaLabel
 * @property {JSX.Element} icon
 * @property {(data: any) => void} onClick
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {Action[]} props.actions
 * @returns {JSX.Element}
 */
const ActionsCellRenderer = props => {
  return (
    <HStack alignItems='center' height='100%' my='auto' spacing={2}>
      {props.actions.map((action, ix) => (
        <IconButton
          key={String(`action-${ix}`)}
          aria-label={action.ariaLabel}
          icon={action.icon}
          p={0}
          size='lg'
          onClick={() => action.onClick(props.data)}
          // eslint-disable-next-line
          tabIndex={1}
          variant='ghost'
        />
      ))}
    </HStack>
  )
}

ActionsCellRenderer.propTypes = {
  actions: types.arrayOf(
    types.shape({
      ariaLabel: types.string,
      icon: types.node,
      onClick: types.func,
    })
  ),
  data: types.object,
}

export default ActionsCellRenderer

import types from 'prop-types'
import { Flex } from '@chakra-ui/react'
import TimelineItem from './TimelineItem'

/**
 *
 * @param {Object} props
 * @param {number} [props.numOfEntries]
 * @returns {JSX.Element}
 */
const TimelineSkeleton = ({ numOfEntries }) => {
  const mockedEntries = Array(numOfEntries)
    .fill('')
    .map((_, ix) => ({
      eventTime: String(ix),
      description: String(ix),
      eventType: String(ix),
    }))
  return (
    <Flex as='figure' direction='column-reverse' width='100%'>
      {mockedEntries.map((entry, ix) => (
        <TimelineItem isLoading key={entry.description} data={entry} />
      ))}
    </Flex>
  )
}

TimelineSkeleton.propTypes = {
  numOfEntries: types.number,
}

TimelineSkeleton.defaultProps = {
  numOfEntries: 5,
}

TimelineSkeleton.displayName = 'TimelineSkeleton'
export default TimelineSkeleton

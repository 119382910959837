import { useBreakpointValue } from '@chakra-ui/react'

const variants = {
  sm: { navigation: 'drawer', navigationButton: true },
  md: { navigation: 'sidebar', navigationButton: false },
}

export const useSidebarVariants = () =>
  useBreakpointValue({
    base: variants.sm,
    md: variants.md,
  })

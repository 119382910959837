import { transparentize } from '@chakra-ui/theme-tools'
import { expand } from './utils'

const colorPalettes = expand({
  'gray, neutral': expand({
    '50, white': '#FFFFFF',
    '10, 100': '#FBFBFB',
    '20, 200': '#F5F5F5',
    '30, 300': '#EBEBEB',
    '40, 400': '#D8D8D8',
    '50, 500': '#A8A8A8',
    '60, 600': '#838383',
    '70, 700': '#606060',
    '80, 800': '#3E3E3E',
    '90, 900, black': '#202020',
  }),
  success: expand({
    '100, light95': '#F6FAF4',
    '200, light60': '#B7D8A4',
    '300, light30': '#81BA60',
    '400, light15': '#66AC3E',
    '500, base': '#4B9D1C',
    '600, dark15': '#408518',
    '700, dark30': '#356E14',
    '800, dark60': '#1E3F0B',
  }),
  warning: expand({
    '100, light95': '#FFFCF5',
    '200, light60': '#FDE8B3',
    '300, light30': '#FBD77B',
    '400, light15': '#FACF5E',
    '500, base': '#F9C642',
    '600, dark15': '#D4A838',
    '700, dark30': '#AE8B2E',
    '800, dark60': '#644F1A',
  }),
  'info, primary': expand({
    '50, light95': '#F4F7FB',
    '100, light60': '#A9C1DF',
    '200, light45': '#88AAD2',
    '300, light30': '#6893C6',
    '400, light15': '#477BBA',
    '500, base': '#2764AE',
    '600, dark15': '#215594',
    '700, dark30': '#1B467A',
    '800, dark45': '#153760',
    '900, dark60': '#102846',
  }),
  danger: expand({
    '100, light95': '#FCF5F7',
    '200, light60': '#EBB3DB',
    '300, light30': '#DC7B8C',
    '400, light15': '#D55E74',
    '500, base': '#CD425B',
    '600, dark15': '#AE384D',
    '700, dark30': '#902E40',
    '800, dark60': '#521A24',
  }),
  'purple, ancillary': expand({
    '100, light95': '#FAF6FD',
    '200, light60': '#D4BBF1',
    '300, light30': '#B388E7',
    '400, light15': '#A36EE1',
    '500, base': '#9355DC',
    '600, dark15': '#7D48BB',
    '700, dark30': '#673C9A',
    '800, dark60': '#3B2258',
  }),
})

const background = {
  main: '#f6f8fa',
  1: colorPalettes.neutral.white,
  2: colorPalettes.neutral[20],
  3: colorPalettes.neutral[30],
  4: colorPalettes.neutral[10],
  nav01: colorPalettes.primary.base,
  nav02: colorPalettes.primary.dark15,
  nav03: colorPalettes.primary.dark30,
  nav04: colorPalettes.primary.dark60,
  logoOverlay01: transparentize(colorPalettes.neutral[90], 0.12),
  iconOverlay01: colorPalettes.primary.base,
  iconOverlay02: colorPalettes.primary.light95,
  iconOverlay03: transparentize(colorPalettes.neutral[90], 0.2),
  modalOverlay03: transparentize(colorPalettes.neutral[90], 0.5),
  tableHeader: '#f8fafe',
}

const border = {
  iconStroke01: colorPalettes.neutral.white,
  iconStroke02: colorPalettes.primary.base,
  iconDisabled: colorPalettes.gray[60],
  iconSuccess: colorPalettes.success.base,
  iconWarning: colorPalettes.warning.base,
  iconDanger: colorPalettes.danger.base,
  uiBorder01: colorPalettes.gray[40],
  uiBorder02: colorPalettes.gray[30],
}

export const colors = Object.assign(colorPalettes, { background, border })

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

dayjs.extend(calendar)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(currentTimezone)

import { useCallback, useEffect, useRef, useState } from 'react'
import types from 'prop-types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import Select from 'shared/components/select'
import { useFacilities } from './services/useFacilities'

/**
 * @typedef {import('./SelectFacilityProps').Facility} Facility
 * @typedef {import('./SelectFacilityProps').FacilityOption} FacilityOption
 */

/**
 * @param {Object} props
 * @param {string} [props.placeholder]
 * @param {string} [props.dataTestId]
 * @returns {React.FunctionComponentElement<any>}
 */
const SelectFacility = ({ dataTestId, placeholder }) => {
  const [currentFacility, setCurrentFacility] = useState(null)
  const { data, loading } = useFacilities()

  const history = useHistory()
  const location = useLocation()
  const prevFacilityId = useRef(null)
  // @ts-ignore
  const { facilityId } = useParams()

  const preselectFacility = useCallback(() => {
    if (data) {
      const currentFacility = data.find(
        facility => facility.value === Number(facilityId)
      )
      if (currentFacility) {
        setCurrentFacility(currentFacility)
      } else {
        history.push('/facilities')
      }
    }
  }, [data, facilityId, history])

  useEffect(() => {
    if (!loading && data && facilityId != null) {
      if (
        !prevFacilityId.current ||
        prevFacilityId.current !== Number(facilityId)
      ) {
        prevFacilityId.current = Number(facilityId)
        preselectFacility()
      }
    }
  }, [data, facilityId, history, loading, preselectFacility])

  /**
   * @param {FacilityOption} newFacility
   * @returns
   */
  const handleSelectFacility = newFacility => {
    setCurrentFacility(newFacility)
    const facilityPathname = generateFacilityPathname(newFacility)

    history.push(facilityPathname)
  }

  /**
   * @param {FacilityOption} nextFacility
   * @returns {string}
   */
  const generateFacilityPathname = nextFacility => {
    if (currentFacility) {
      const { pathname } = location
      const [baseRoute, restPathname] = pathname.split(currentFacility.value)
      return `${baseRoute}${nextFacility.value}${restPathname}`
    }
    return `/facilities/${nextFacility.value}`
  }

  return (
    <Box data-testid={dataTestId} width='100%'>
      <Select
        id='select-facility'
        isDisabled={loading}
        isLoading={loading}
        options={data}
        onChange={handleSelectFacility}
        placeholder={placeholder}
        value={currentFacility}
      />
    </Box>
  )
}

SelectFacility.propTypes = {
  dataTestId: types.string,
  placeholder: types.string,
}
SelectFacility.defaultProps = {
  dataTestId: 'select-facility',
  placeholder: 'Select a facility',
}

SelectFacility.displayName = 'SelectFacility'
export default SelectFacility

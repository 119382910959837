import types from 'prop-types'
import { Box, Fade, Progress } from '@chakra-ui/react'

/**
 *
 * @param {Object} props
 * @param {string} props.ariaLabel
 * @param {boolean} props.isVisible
 * @returns {JSX.Element}
 */
const LoadingOverlay = ({ ariaLabel, isVisible, ...props }) => {
  return (
    <Fade in={isVisible}>
      <Box
        bg='whiteAlpha.600'
        cursor={isVisible ? 'wait' : 'auto'}
        height='100%'
        position='absolute'
        top={0}
        inset={0}
        pointerEvents={isVisible ? 'auto' : 'none'}
        {...props}>
        <Progress
          aria-label={ariaLabel}
          size='xs'
          isIndeterminate
          position='sticky'
          top={0}
          width='100%'
        />
      </Box>
    </Fade>
  )
}

LoadingOverlay.propTypes = {
  ariaLabel: types.string,
  isVisible: types.bool,
}
LoadingOverlay.defaultProps = {
  ariaLabel: 'Loading',
  isVisible: true,
}

LoadingOverlay.displayName = 'LoadingOverlay'
export default LoadingOverlay

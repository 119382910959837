import isValidHTMLProp from '@emotion/is-prop-valid'
import { shouldForwardProp as chakraShouldForwardProp } from '@chakra-ui/react'

/**
 * @param {string} prop
 * @returns {boolean} shouldForward
 */
export function shouldForwardProp(prop) {
  // don't forward Chakra's props
  const isChakraProp = !chakraShouldForwardProp(prop)
  if (isChakraProp) return false

  // forward valid HTML props
  const isValidProp = isValidHTMLProp(prop)
  if (isValidProp) return true

  // else, only forward `sample` prop
  return ['sample'].includes(prop)
}

import { useQuery } from '@apollo/client'
import GetFacilities from 'shared/graphql/queries/GetFacilities.gql'

/**
 * @typedef {import('../SelectFacilityProps').ProcessedResponse} ProcessedResponse
 * @typedef {import('../SelectFacilityProps').QueryResponseData} QueryResponseData
 * @typedef {import('../SelectFacilityProps').Facility} Facility
 * @typedef {import('../SelectFacilityProps').ListOfFacilities} ListOfFacilities
 */

/**
 * @returns {ProcessedResponse}
 */
export const useFacilities = () => {
  const { data, error, loading } = useQuery(GetFacilities)
  return {
    data: formatData(data),
    loading,
    error,
  }
}

/**
 * @param {QueryResponseData} data
 * @returns {ListOfFacilities}
 */
function formatData(data) {
  if (!data || data.facilities.nodes.length === 0) {
    return []
  }

  return data.facilities.nodes.map(facility => ({
    label: facility.name,
    value: facility.id,
  }))
}

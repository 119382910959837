import types from 'prop-types'
import { Box, chakra, Flex, Text, Tooltip } from '@chakra-ui/react'
import { shouldForwardProp } from 'shared/utils'

/**
 * @typedef {import('./SegmentedProgressProps').SegmentedProgressProps} SegmentedProgressProps
 */

/**
 * @param {SegmentedProgressProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const SegmentedProgress = ({
  bg,
  height,
  segments,
  percentCompleted,
  showSegmentLabel,
  total,
  width,
  ...props
}) => {
  return (
    <Flex direction='column'>
      <Flex
        align='flex-start'
        bg={bg}
        borderRadius='base'
        height={height}
        width={width}
        {...props}>
        {segments.map(segment => (
          <Tooltip
            key={segment.id}
            hasArrow
            label={segment.label ?? String(segment.value)}>
            <Segment
              bg={segment.color}
              height='100%'
              value={segment.value}
              width={`${(segment.value * 100) / total}%`}>
              {showSegmentLabel ? (
                <Text
                  color='gray.700'
                  fontSize='xs'
                  mt={2}
                  textAlign='center'
                  whiteSpace='nowrap'>
                  {`${segment.value}h`}
                </Text>
              ) : null}
            </Segment>
          </Tooltip>
        ))}
      </Flex>
      <Flex width='calc(100% - 2px)' ml='1px' mt={`calc(-${height} + 1px)`}>
        <Flex height={`calc(${height} - 2px)`} width={`${percentCompleted}%`} />
        <Flex
          height={`calc(${height} - 2px)`}
          width={`${100 - percentCompleted}%`}
          borderRadius='base'
          bg='whiteAlpha.700'
        />
      </Flex>
    </Flex>
  )
}

const Segment = chakra(Box, {
  baseStyle: {
    ':first-of-type': {
      borderTopLeftRadius: 'base',
      borderBottomLeftRadius: 'base',
    },

    ':last-of-type': {
      borderTopRightRadius: 'base',
      borderBottomRightRadius: 'base',
    },
  },
  shouldForwardProp,
})

SegmentedProgress.propTypes = {
  bg: types.any,
  height: types.any,
  segments: types.arrayOf(
    types.shape({
      color: types.string,
      id: types.string,
      label: types.string,
      value: types.number,
    })
  ),
  percentCompleted: types.number,
  showSegmentLabel: types.bool,
  total: types.number.isRequired,
  width: types.any,
}
SegmentedProgress.defaultProps = {
  bg: 'gray.300',
  height: '6px',
  showSegmentLabel: false,
  percentCompleted: 100,
  width: '100%',
}

SegmentedProgress.displayName = 'SegmentedProgress'
export default SegmentedProgress

import types from 'prop-types'
import { chakra, Flex, Skeleton, Text } from '@chakra-ui/react'

import { shouldForwardProp } from 'shared/utils'

/**
 * @typedef {import('./TimelineTabProps').TimelineItemProps} TimelineItemProps
 */

/**
 * @param {TimelineItemProps} props
 * @returns {JSX.Element}
 */
const TimelineItem = ({ data, isLoading }) => {
  return (
    <Container alignItems='center' py={4} width='100%'>
      <Skeleton borderRadius='round' isLoaded={!isLoading}>
        <Bullet radius='round' />
      </Skeleton>
      <Flex direction='column' mx={4} width='100%'>
        <Skeleton isLoaded={!isLoading}>
          <Text color='gray.600' fontSize='xs'>
            {data.eventTime}
          </Text>
        </Skeleton>
        <Skeleton isLoaded={!isLoading} my={1}>
          <Text fontSize='md' noOfLines={2} title={data.description}>
            {data.description}
          </Text>
        </Skeleton>
      </Flex>
    </Container>
  )
}

const Bullet = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    bg: 'white',
    borderRadius: 'round',
    boxShadow: 'timelineBullet',
    justifyContent: 'center',
    minHeight: '24px',
    minWidth: '24px',
    height: '24px',
    position: 'relative',
    width: '24px',
    '::after': {
      bg: 'info.base',
      borderRadius: 'round',
      content: '""',
      height: '10px',
      width: '10px',
    },
  },
  shouldForwardProp,
})

const Container = chakra(Flex, {
  baseStyle: {
    [`:not(:last-of-type) ${Bullet}`]: {
      '::before': {
        bg: 'info.base',
        content: '""',
        left: '50%',
        height: '36px',
        position: 'absolute',
        transform: 'translate(-50%, 50%)',
        top: '100%',
        width: '1px',
      },
    },
  },
})

TimelineItem.propTypes = {
  data: types.shape({
    description: types.string,
    eventTime: types.string,
    eventType: types.string,
  }),
  isLoading: types.bool,
}

TimelineItem.displayName = 'TimelineItem'
export default TimelineItem

import types from 'prop-types'
import { useToken } from '@chakra-ui/react'
/**
 * @typedef {import('./IconProps').IconProps} IconProps
 */

/**
 * @param {IconProps} props
 * @returns {JSX.Element}
 */
const AnalysisIcon = ({ isActive, ...props }) => {
  const [activeIconColor, inactiveBaseIconColor, inactiveIconColor, white] =
    useToken('colors', ['primary.base', 'gray.700', 'gray.400', 'white'])

  return (
    <svg
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g id='Nav-Medium/Dashboard-Circle'>
        <path
          id='Oval'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z'
          fill={isActive ? activeIconColor : 'none'}
          stroke={isActive ? white : inactiveIconColor}
        />
        <g id='Icon/Arrows/Chevron-Left Copy 3'>
          <g id='pie-chart'>
            <path
              id='Path'
              d='M19.1398 15.5934C17.973 18.3526 15.109 19.9947 12.1383 19.6077C9.16762 19.2207 6.8199 16.8997 6.399 13.9336C5.97809 10.9675 7.58739 8.08492 10.3331 6.88672'
              stroke={isActive ? white : inactiveBaseIconColor}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Path_2'
              fillRule='evenodd'
              clipRule='evenodd'
              d='M19.6667 13.0002C19.6667 11.2321 18.9643 9.53636 17.714 8.28612C16.4638 7.03587 14.7681 6.3335 13 6.3335V13.0002H19.6667Z'
              stroke={isActive ? white : inactiveBaseIconColor}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

AnalysisIcon.propTypes = {
  isActive: types.bool,
}
AnalysisIcon.defaultProps = {
  isActive: false,
}

AnalysisIcon.displayName = 'AnalysisIcon'
export default AnalysisIcon

import { Box, Image, Text } from '@chakra-ui/react'
import workInProgress from 'assets/images/work-in-progress.png'

/**
 * @param {Object} props
 * @param {number| string | Object} [props.height]
 * @param {number| string | Object} [props.mx]
 * @param {number| string | Object} [props.my]
 * @param {number| string | Object} [props.maxHeight]
 * @param {number| string | Object} [props.maxWidth]
 * @param {number| string | Object} [props.minHeight]
 * @param {number| string | Object} [props.minWidth]
 * @param {number| string | Object} [props.width]
 * @returns {React.FunctionComponentElement<any>}
 */
const WorkInProgress = props => {
  return (
    <Box as='figure' {...props}>
      <Image height='fit-content' objectFit='contain' src={workInProgress} />
      <Text
        as='figcaption'
        display='flex'
        fontSize='xl'
        justifyContent='center'
        textAlign='center'
        width='100%'>
        This feature is still in progress. Bear with us.
      </Text>
    </Box>
  )
}

WorkInProgress.propTypes = {}

WorkInProgress.displayName = 'WorkInProgress'
export default WorkInProgress

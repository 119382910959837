import types from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { HStack, Link, useToken } from '@chakra-ui/react'

import { useStackNavigation } from 'shared/hooks'

/**
 * @typedef {import('./HeaderNavLinksProps').Route} Route
 * @typedef {import('./HeaderNavLinksProps').ChildRoute} ChildRoute
 */

/**
 * @param {Object} props
 * @param {Route[]} props.routes
 * @returns {React.FunctionComponentElement<any>}
 */
const HeaderNavLinks = ({ routes }) => {
  const location = useLocation()

  const [activeLinkColor, inactiveLinkColor] = useToken('colors', [
    'primary.base',
    'gray.700',
  ])

  const navRef = useStackNavigation({ direction: 'horizontal' })

  const currentRouteConfig = routes.find(route =>
    location.pathname.includes(route.path)
  )
  const childRoutes = currentRouteConfig ? currentRouteConfig.childRoutes : []

  /**
   * @param {string} linkPath
   * @returns {string} full link path
   */
  const buildLinkPath = linkPath => {
    const [basePath] = location.pathname.split(currentRouteConfig.path)
    return basePath.concat(currentRouteConfig.path, '/', linkPath)
  }

  return (
    <HStack as='nav' align='center' ref={navRef} spacing={6}>
      {childRoutes.map(route => (
        <Link
          as={NavLink}
          // TODO: update with the new API when react-router-dom stable version gets released
          // https://github.com/remix-run/react-router/releases/tag/v6.0.0-beta.3
          activeStyle={{
            borderBottomColor: `${activeLinkColor}`,
            color: activeLinkColor,
          }}
          borderBottom='1px'
          borderBottomColor='transparent'
          color={inactiveLinkColor}
          fontSize={{ base: 'sm', lg: 'md' }}
          key={route.name}
          py={1}
          whiteSpace='nowrap'
          to={buildLinkPath(route.path)}>
          {route.name}
        </Link>
      ))}
    </HStack>
  )
}

HeaderNavLinks.propTypes = {
  routes: types.arrayOf(types.shape({})),
}

HeaderNavLinks.displayName = 'HeaderNavLinks'
export default HeaderNavLinks

import types from 'prop-types'
import {
  Stat as ChakraStat,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react'

/**
 * @typedef {import('./StatProps').StatProps} StatProps
 */

/**
 * @param {StatProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const Stat = ({ children, label, percentage, value }) => {
  const percentageColor = (() => {
    if (percentage > 50) {
      return 'success.base'
    }
    if (percentage < 50 && percentage > 20) {
      return 'warning.base'
    }
    return 'danger.base'
  })()

  return (
    <ChakraStat>
      <StatLabel color='gray.700' fontSize='xs' whiteSpace='nowrap'>
        {label}
      </StatLabel>
      <StatNumber
        alignItems='center'
        display='flex'
        whiteSpace='nowrap'
        fontSize={{ xs: 'md', lg: 'lg' }}
        fontWeight='medium'>
        {value ?? children}{' '}
        {percentage ? (
          <Text as='div' color={percentageColor} ml={3}>
            ({percentage}%)
          </Text>
        ) : null}
      </StatNumber>
    </ChakraStat>
  )
}

Stat.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]),
  label: types.string.isRequired,
  percentage: types.number,
  value: types.oneOfType([types.number, types.string]),
}

Stat.displayName = 'Stat'
export default Stat

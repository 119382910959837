import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ChakraProvider } from '@chakra-ui/provider'

import Auth0Provider from './services/Auth0ProviderWithHistory'
import ApolloProvider from './services/AuthorizedApolloProvider'
import { NetworkStatusProvider } from './services/NetworkStatusContext'
import UserProvider from 'services/UserProvider'

import { AppRouter } from './router'
import appTheme from './assets/theme'
import './config/dayjs'

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Auth0Provider>
          <ApolloProvider>
            <UserProvider>
              <NetworkStatusProvider>
                <ChakraProvider theme={appTheme}>
                  <AppRouter />
                </ChakraProvider>
              </NetworkStatusProvider>
            </UserProvider>
          </ApolloProvider>
        </Auth0Provider>
      </Router>
    </HelmetProvider>
  )
}

App.displayName = 'Root'
export default App
